import React, { useState, useEffect } from "react";
import NavBar from "../../utils/nav/NavBar";
import Tabs from "../../utils/tabs";

import {
  GetUsuariosPerfil,
  GetPermisosPerfil,
  GetPermisos,
  PostPerfil,
  PutPerfil,
  PatchPerfilPermisos,
  PostUser,
  PutUser,
} from "../../../services/usersServices";
import ButtonAdd from "../../utils/buttonAdd";
import Checkbox from "../../utils/checkBox";
import ButtonSave from "../../utils/buttonSave";
import EditUser from "./editUser";

const EditProfile = ({ perfil, onClose, onSave }) => {
  const [selectedTab, setSelectedTab] = useState("Usuarios");
  const [usuarios, setUsuarios] = useState([]);
  const [permisos, setPermisos] = useState([]);
  const [permisosPerfil, setPermisosPerfil] = useState([]);
  const [nombrePerfil, setNombrePerfil] = useState(perfil.nombre);
  const [filterDisabled, setFilterDisabled] = useState(true);

  const [editingUser, setEditingUser] = useState();

  useEffect(() => {
    cargar();
    window.scrollTo({ top: 0 });
  }, []);

  const cargar = async () => {
    try {
      let permisos = await GetPermisos();

      if (perfil.id) {
        let usuarios = await GetUsuariosPerfil(perfil.id);
        setUsuarios(usuarios);
        let permisosPerfil = await GetPermisosPerfil(perfil.id);
        permisosPerfil.forEach((pf) => {
          let permisoIndex = permisos.findIndex((p) => p.id === pf.permisoId);
          if (permisoIndex > -1) permisos[permisoIndex].checked = true;
        });
        setPermisosPerfil(permisosPerfil);
      }
      setPermisos(permisos);
    } catch (error) {
      alert(error);
    }
  };

  const handlePermisoChanged = (permiso) => {
    if (permiso.checked) {
      let permisoPerfilIndex = permisosPerfil.findIndex(
        (p) => p.permisoId === permiso.id
      );
      permisosPerfil.splice(permisoPerfilIndex, 1);
      permiso.checked = false;
    } else {
      permiso.checked = true;
      permisosPerfil.push({ ...permiso });
    }
    let i = permisos.findIndex((p) => p.id === permiso.id);
    permisos[i] = permiso;
    setPermisos([...permisos]);
  };

  const handleSave = async () => {
    let perfil = await savePerfil();
    if (!perfil) return;

    if (await !savePermisos(perfil)) return;
    if (await !saveUsers(perfil)) return;

    onSave(perfil);
  };

  const savePerfil = async () => {
    if (!nombrePerfil) {
      alert("Escriba el nombre del perfil");
      return null;
    }
    let perfilEditado = { ...perfil };
    perfilEditado.nombre = nombrePerfil;

    try {
      if (!perfil.id) {
        perfilEditado = await PostPerfil(perfilEditado);
      } else {
        await PutPerfil(perfilEditado);
      }
      return perfilEditado;
    } catch (error) {
      alert(error);
      return null;
    }
  };

  const saveUsers = async (perfil) => {
    try {
      for (var i = 0; i < usuarios.length; i++) {
        let user = usuarios[i];
        if (!user.id) {
          user.perfilUsuarioId = perfil.id;
          await PostUser(user);
        } else {
          await PutUser(user);
        }
      }

      return true;
    } catch (error) {
      alert(error);
      return false;
    }
  };

  const savePermisos = async (perfil) => {
    try {
      let permisosAsignados = permisos.filter((p) => p.checked);
      PatchPerfilPermisos(perfil.id, permisosAsignados);

      return true;
    } catch (error) {
      alert(error);
      return false;
    }
  };

  const handleUserEdited = (user) => {
    setEditingUser(null);
    if (!user.id) {
      usuarios.push(user);
    } else {
      let index = usuarios.findIndex((x) => x.id === user.id);
      if (index > -1) usuarios[index] = user;
    }
    setUsuarios([...usuarios]);
  };

  if (editingUser)
    return (
      <EditUser
        usuario={editingUser}
        onClose={() => setEditingUser(null)}
        onSave={(user) => handleUserEdited(user)}
      />
    );

  return (
    <div id="edit-user-profile">
      <div className="page-header">
        <NavBar
          title={!perfil.id ? "Nuevo perfil" : "Editar perfil"}
          navButtonComponent={
            <i
              className="fas fa-arrow-left nav-button-back"
              onClick={() => onClose()}
            />
          }
          toolbar={<ButtonSave onClick={() => handleSave()} />}
        />
      </div>
      <div className="page--content">
        <div className="form ">
          <label htmlFor="">Nombre:</label>
          <input
            type="text"
            value={nombrePerfil}
            onChange={(e) => setNombrePerfil(e.currentTarget.value)}
          />
        </div>
        <div className="edit-profile--tabs-container">
          <Tabs
            tabs={["Usuarios", "Permisos"]}
            selectedTab={selectedTab}
            onSelectedTabChanged={(tab) => setSelectedTab(tab)}
          />

          {selectedTab === "Usuarios" && (
            <ButtonAdd onClick={() => setEditingUser({})} />
          )}
        </div>

        {selectedTab === "Usuarios" && (
          <div className="tab-content">
            <Checkbox
              label="Ocultar deshabilitados"
              checked={filterDisabled}
              onCheckedChanged={() => setFilterDisabled(!filterDisabled)}
            />
            <div className="page-list">
              {usuarios
                .filter(
                  (u) =>
                    !filterDisabled ||
                    (filterDisabled & !u.deshabilitado &&
                      (!u.perfilUsuarioId || u.perfilUsuarioId === perfil.id))
                )
                .map((usuario) => (
                  <div
                    key={usuario.id}
                    className="list-item"
                    onClick={() => setEditingUser(usuario)}
                  >
                    <div
                      className={usuario.deshabilitado ? " user-disabled " : ""}
                    >
                      {usuario.nombre}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
        {selectedTab === "Permisos" && (
          <div className="tab-content">
            <div className="page-list">
              {permisos.map((permiso) => (
                <div key={permiso.id} className="list-item">
                  <Checkbox
                    label={permiso.clave}
                    checked={permiso.checked}
                    onCheckedChanged={() => handlePermisoChanged(permiso)}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditProfile;
