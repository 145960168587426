import React, { useState, useEffect } from "react";
import NavBar from "../../utils/nav/NavBar";
import NavMenu from "../../utils/nav/NavMenu";
import Loading from "../../utils/loading";
import Authorization from "../../utils/secure/Authorization";
import { useLocation } from "react-router";
import { GetRutasPorEnviar } from "../../../services/rutasServices";
import moment from "moment";
import KickoffRoute from "./kickoff-route";

import "../../../css/kickoff.css";

const KickOffRoutes = () => {
  const [permissionChecked, setPermissionChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rutas, setRutas] = useState([]);
  const [selectedRuta, setSelectedRuta] = useState();

  let query = new URLSearchParams(useLocation().search);

  useEffect(() => {
    cargarRutas();
  }, []);

  const cargarRutas = async () => {
    try {
      setLoading(true);
      let fecha = query.get("fecha");
      if (!fecha) fecha = moment();
      let rutas = await GetRutasPorEnviar(fecha);
      setRutas(rutas);
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
    }
  };

  if (selectedRuta)
    return (
      <KickoffRoute
        ruta={selectedRuta}
        onClose={() => {
          setSelectedRuta(null);
          cargarRutas();
        }}
      />
    );
  return (
    <div id="kickoff-routes">
      {!permissionChecked && (
        <Authorization
          permission="Pedidos/Armado"
          onPermissionGranted={() => setPermissionChecked(true)}
        />
      )}
      <div className="page-header">
        <NavBar title="Envío de rutas" navButtonComponent={<NavMenu />} />
      </div>
      <div className="page-content">
        <div className="page-list">
          {rutas.map((r) => (
            <div key={r.rutaId} className="card">
              <div className="font-small">
                {r.numero} - {r.nombre}
              </div>
              <div className="font-small">{r.chofer}</div>
              <button
                className="btn primary"
                onClick={() => setSelectedRuta(r)}
              >
                Entregar
              </button>
            </div>
          ))}
        </div>
      </div>

      {loading && <Loading />}
    </div>
  );
};

export default KickOffRoutes;
