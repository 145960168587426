import React, { useState, useEffect } from "react";
import NavBar from "../../utils/nav/NavBar";
import Loading from "../../utils/loading";
import { EnviarRuta } from "../../../services/rutasServices";

const KickoffRoute = ({ ruta, onClose }) => {
  const [repartidor, setRepartidor] = useState("");
  const [cajas, setCajas] = useState("");
  const [hieleras, setHieleras] = useState("");
  const [terminal, setTerminal] = useState("");
  const [loading, setLoading] = useState(false);

  const enviar = async () => {
    try {
      setLoading(true);

      await EnviarRuta(ruta.rutaId, {
        cajas: Number(cajas),
        hieleras: Number(hieleras),
        terminal,
        repartidor: repartidor,
      });
      onClose();
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div id="kickoffRoute">
      <div className="page-header">
        <NavBar
          title={"Enviar ruta " + ruta.numero}
          navButtonComponent={
            <i
              className="fas fa-arrow-left nav-button-back"
              onClick={() => onClose()}
            />
          }
        />
      </div>
      <div className="page-content">
        <div className="card">
          <div className="font-small">{ruta.nombre}</div>
          <div className="font-small">{ruta.chofer}</div>
          <div className="form" id="kickoffRoute--form">
            <div className="form-row">
              <div className="form-label">Repartidor</div>
              <input
                type="text"
                value={repartidor}
                onChange={(e) => setRepartidor(e.currentTarget.value)}
              />
            </div>
            <div className="form-row">
              <div className="form-label">Cajas</div>
              <input
                type="number"
                value={cajas}
                onChange={(e) => setCajas(e.currentTarget.value)}
              />
            </div>
            <div className="form-row">
              <div className="form-label">Hieleras</div>
              <input
                type="number"
                value={hieleras}
                onChange={(e) => setHieleras(e.currentTarget.value)}
              />
            </div>
            <div className="form-row">
              <div className="form-label">Terminal</div>
              <input
                type="text"
                value={terminal}
                onChange={(e) => setTerminal(e.currentTarget.value)}
              />
            </div>
          </div>
          <div className="align-right">
            <button className="btn primary" onClick={() => enviar()}>
              Enviar
            </button>
          </div>
        </div>
      </div>

      {loading && <Loading />}
    </div>
  );
};

export default KickoffRoute;
