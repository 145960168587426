import React, { useEffect, useState, useRef } from "react";

const AutoComplete = ({
  id,
  placeHolder,
  items,
  value,
  onItemSelected,
  itemKey,
  itemDisplay,
  itemClassName,
}) => {
  const [text, setText] = useState();
  const [showPopup, setShowPopup] = useState(false);

  const useFocus = () => {
    const htmlElRef = useRef(null);
    const setFocus = () => {
      htmlElRef.current && htmlElRef.current.focus();
    };

    return [htmlElRef, setFocus];
  };
  const [inputRef, setInputFocus] = useFocus();

  useEffect(() => {
    if (value) {
      setText(itemDisplay(value));
      onItemSelected(value);
    }
  }, [value]);

  const handleChange = (e) => {
    let txt = e.currentTarget.value;
    if (!txt) setShowPopup(false);
    else setShowPopup(true);
    setText(txt);
    onItemSelected(null);
  };

  const clearSearchText = () => {
    setShowPopup(false);
    setText("");
    onItemSelected(null);
    setInputFocus();
  };

  const filteredItems = () => {
    if (!text) return;

    let lowerText = text.toLowerCase();

    let filtered = items.filter((p) =>
      itemDisplay(p).toLowerCase().includes(lowerText)
    );

    return filtered;
  };

  const handleItemClick = (item) => {
    setText(itemDisplay(item));
    onItemSelected(item);
    setShowPopup(false);
  };

  const getItemClassName = (item) => {
    let rValue = "autocomplete--popup-item ";
    if (itemClassName) rValue += itemClassName(item);

    return rValue;
  };

  return (
    <div className="autocomplete" id={id}>
      <div className="autocomplete--entry">
        <input
          type="text"
          ref={inputRef}
          value={text}
          placeholder={placeHolder}
          onChange={handleChange}
        />
        <div className="autocomplete--entry-clear">
          {text && (
            <i
              className="fas fa-times search--clear-button"
              onClick={(e) => clearSearchText()}
            ></i>
          )}
        </div>
      </div>

      {showPopup && (
        <div className="autocomplete--popup">
          {filteredItems().map((item) => (
            <div
              id={itemKey(item)}
              className={getItemClassName(item)}
              onClick={() => handleItemClick(item)}
            >
              {itemDisplay(item)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AutoComplete;
