import React from "react";
import NavBar from "../utils/nav/NavBar";
import NavMenu from "../utils/nav/NavMenu";

const Home = () => {
  return (
    <div>
      <div className="page-header">
        <NavBar title="Principal" navButtonComponent={<NavMenu />} />
      </div>
      <div className="page-content"></div>
    </div>
  );
};

export default Home;
