import React from "react";

import NavMenu from "./NavMenu";

import "../../../css/nav.css";

const NavBar = ({ title, navButtonComponent, toolbar }) => {
  return (
    <div id="navbar" className="bar bar--double">
      {navButtonComponent}
      <div id="navbar-title">{title}</div>
      <div id="navbar-toolbar">{toolbar}</div>
    </div>
  );
};

export default NavBar;
