import webAPIServices from "./webAPIServices";

export async function GetPurchaseReceipt(fecha) {
  const appsvc = new webAPIServices();

  let requestUrl = `/backoffice/purchase/purchase-receipt/${appsvc.FormatFecha(
    fecha
  )}`;

  return appsvc.Get(requestUrl);
}

export async function PutPurchaseReceipt(itemChanged) {
  const appsvc = new webAPIServices();

  let requestUrl = `/backoffice/purchase/purchase-receipt`;

  return appsvc.Put(requestUrl, itemChanged);
}

export async function PostPurchaseReceipt(newItem) {
  const appsvc = new webAPIServices();

  console.log("newItem - post", newItem);

  let requestUrl = `/backoffice/purchase/purchase-receipt`;

  return appsvc.Post(requestUrl, newItem);
}

export async function GetPurchaseReceiptLookupProductos() {
  const appsvc = new webAPIServices();

  let requestUrl = `/backoffice/purchase/purchase-receipt/products`;

  return appsvc.Get(requestUrl);
}

export async function GetProveedores() {
  const appsvc = new webAPIServices();

  let requestUrl = `/backoffice/proveedores`;

  return appsvc.Get(requestUrl);
}
