import webAPIServices from "./webAPIServices";

import moment from "moment";

export async function GetRutas(fecha) {
  const appsvc = new webAPIServices();

  if (!fecha) fecha = moment();

  let requestUrl = `/backoffice/Rutas?fecha=${appsvc.FormatFecha(fecha)}`;

  return appsvc.Get(requestUrl);
}

export async function GetPedidosRuta(rutaId) {
  const appsvc = new webAPIServices();
  return appsvc.Get(`/backoffice/Pedidos/Ruta/${rutaId}`);
}

export async function GetRutasPorEnviar(fecha) {
  const appsvc = new webAPIServices();
  return appsvc.Get(
    `/backoffice/Rutas/PorEnviar?fecha=${appsvc.FormatFecha(fecha)}`
  );
}

export async function EnviarRuta(rutaId, envioRuta) {
  console.log(rutaId, envioRuta);
  const appsvc = new webAPIServices();
  return appsvc.Post(`/backoffice/Rutas/Enviar/${rutaId}`, envioRuta);
}
