import React, { useEffect, useState } from "react";
import { GetBackofficeUserSession } from "../../../services/sessionServices";

const MenuHeaderUser = () => {
  const [userName, setUserName] = useState("");
  useEffect(() => {
    let session = GetBackofficeUserSession();
    if (session) setUserName(session.userName);
  }, []);
  return (
    <div id="menu-header-user">
      <div id="menu-header-avatar">{userName[0]}</div>
      <div id="menu-header-username">{userName}</div>
    </div>
  );
};

export default MenuHeaderUser;
