import React from "react";

const Checkbox = ({ label, checked, onCheckedChanged }) => {
  return (
    <div className="checkbox" onClick={onCheckedChanged}>
      <div className="checkbox--btn">
        {checked && <i className="far fa-check-circle"></i>}
        {!checked && <i className="far fa-circle"></i>}
      </div>
      <div className="checkbox--label">{label}</div>
    </div>
  );
};

export default Checkbox;
