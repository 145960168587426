import React, { useState, useEffect } from "react";
import Authorization from "../../utils/secure/Authorization";
import NavBar from "../../utils/nav/NavBar";
import NavMenu from "../../utils/nav/NavMenu";
import ButtonAdd from "../../utils/buttonAdd";
import Search from "../../utils/search";
import { GetProveedores } from "../../../services/purchaseServices";
import ScrollToTopButton from "../../utils/scrollToTopButton";
import EditProveedor from "./editProveedor";

import "./proveedores.css";
import PillOptions from "../../utils/pillOptions";

const Proveedores = () => {
  const [permissionChecked, setPermissionChecked] = useState();
  const [loading, setLoading] = useState(false);
  const [editingProveedor, setEditingProveedor] = useState();

  const [filteredItems, setFilteredItems] = useState([]);
  const [filtroEstatus, setFiltroEstatus] = useState("Activos");

  const [searchText, setSearchText] = useState("");
  const [proveedores, setProveedores] = useState([]);

  useEffect(() => {
    cargar();
  }, []);

  const cargar = async () => {
    try {
      setLoading(true);
      let p = await GetProveedores();
      setProveedores(p);
      filterItems(p);
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (search) => {
    setSearchText(search.toLowerCase());
    filterItems(proveedores, search, 0);
  };

  const filterItems = (
    prov = proveedores,
    search = searchText,
    filterStatus = filtroEstatus
  ) => {
    let filtered = prov;

    if (search) {
      let searchLowerCase = search.toLowerCase();
      filtered = prov.filter((x) =>
        x.nombre.toLowerCase().includes(searchLowerCase)
      );
    }

    if (filterStatus === "Activos")
      filtered = filtered.filter((x) => !x.deshabilitado);
    if (filterStatus === "Inactivos")
      filtered = filtered.filter((x) => x.deshabilitado);

    setFilteredItems(filtered);
  };

  const handleProveedorEdited = (proveedor) => {
    setEditingProveedor();

    if (!proveedor) return;

    if (!editingProveedor.id) {
      cargar();
      return;
    }

    let index = proveedores.findIndex((x) => x.id === proveedor.id);
    if (index > -1) {
      proveedores[index] = proveedor;
      setProveedores([...proveedores]);
      filterItems(proveedores);
    }
  };

  if (editingProveedor)
    return (
      <EditProveedor
        proveedor={editingProveedor}
        onClose={() => handleProveedorEdited(null)}
        onSave={(proveedor) => handleProveedorEdited(proveedor)}
      />
    );

  return (
    <div id="proveedores">
      {!permissionChecked && (
        <Authorization
          permission="Catálogos/Proveedores/ABC"
          onPermissionGranted={() => setPermissionChecked(true)}
        />
      )}

      <ScrollToTopButton />

      <div className="page-header">
        <NavBar
          title="Proveedores"
          navButtonComponent={<NavMenu />}
          toolbar={
            !loading && <ButtonAdd onClick={() => setEditingProveedor({})} />
          }
        />
        <div className="bar single proveedores--filtro-status">
          <PillOptions
            options={["Activos", "Inactivos", "Todos"]}
            variant="primary"
            selected={filtroEstatus}
            onSelect={(option) => {
              setFiltroEstatus(option);
              filterItems(undefined, undefined, option);
            }}
          />
        </div>
      </div>

      <div className="page-content">
        <Search
          initialSearchText={searchText}
          onSearch={(searchText) => handleSearch(searchText)}
        />

        <div className="page-list">
          {filteredItems.map((proveedor) => (
            <div
              key={proveedor.id}
              className={
                "list-item " + (proveedor.deshabilitado ? " disabled" : " ")
              }
              onClick={() => setEditingProveedor(proveedor)}
            >
              <div>{proveedor.nombre}</div>
              {proveedor.deshabilitado && (
                <div className="proveedor--pill-deshabilitado pill danger">
                  Inactivo
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Proveedores;
