import Axios from "axios";
import moment from "moment";

//TODO: webAPI Authentication

export default class webAPIServices {
  #baseUrl;
  constructor() {
    // Para poder invocar sitios sin SSL valido (development)
    process.env.NODE_TLS_REJECT_UNAUTHORIZED = "0";
    this.#baseUrl = this.#getBaseUrl();
  }

  #getBaseUrl() {
    let rValue = "";
    switch (process.env.REACT_APP_ENVIRONMENT) {
      case "development":
        rValue = process.env.REACT_APP_WEBAPI_URL_DEVELOPMENT;
        break;
      case "staging":
        rValue = process.env.REACT_APP_WEBAPI_URL_STAGING;
        break;
      case "production":
        rValue = process.env.REACT_APP_WEBAPI_URL_PRODUCTION;
        break;
      default: {
        console.log("INVALID ENVIRONMENT", process.env.REACT_APP_ENVIRONMENT);
        rValue = "/";
        break;
      }
    }
    return rValue;
  }

  FormatFecha(fecha) {
    return moment(fecha).format("YYYY-MM-DD");
  }

  async Get(endPoint) {
    const result = await Axios.get(this.#baseUrl + endPoint);
    return result.data;
  }

  async GetBlob(endPoint) {
    const result = await Axios.get(this.#baseUrl + endPoint, {
      responseType: "blob",
    });
    return result.data;
  }
  async Post(endPoint, requestBody, config) {
    const result = await Axios.post(
      this.#baseUrl + endPoint,
      requestBody,
      config
    );
    return result.data;
  }

  async Put(endPoint, requestBody) {
    const result = await Axios.put(this.#baseUrl + endPoint, requestBody);
    return result.data;
  }

  async Patch(endPoint, requestBody) {
    const result = await Axios.patch(this.#baseUrl + endPoint, requestBody);
    return result.data;
  }

  async Delete(endPoint, requestBody) {
    const result = await Axios.delete(this.#baseUrl + endPoint, {
      data: requestBody,
    });
    return result.data;
  }
}
