import React, { useState, useEffect, Fragment } from "react";
import QuantityEntry from "../../utils/quantityEntry";
import { GetDiferenciaStyle } from "../../utils/utils";
import { round } from "../../../services/mathServices";

const FulfillOrderPartidaDialog = ({
  disabled,
  partida,
  onClose,
  onUpdateCantidadSurtida,
}) => {
  const [surtido, setSurtido] = useState("");
  const [kilosSurtidos, setKilosSurtidos] = useState("");

  useEffect(() => {
    if (partida.cantidadSurtida) setSurtido(partida.cantidadSurtida);
    if (partida.kilosSurtidos) setKilosSurtidos(partida.kilosSurtidos);
  }, []);

  return (
    <div className="modal">
      <div className="modal--content">
        <div className="modal--header">
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              onClose();
            }}
          >
            <i className="fas fa-arrow-left" />
          </a>
          <span> Surtir producto</span>
        </div>
        <div className="modal--body">
          <div className="list-item">
            <div className="col1 div-center">
              <img src={partida.imageUrl} alt="" className="thumbnail" />
            </div>
            <div className="col2">
              <div className="list-item--heading">
                <div>
                  {partida.categoria} / {partida.descripcion}
                </div>
                {partida.cliente && <div>{partida.cliente}</div>}
              </div>

              {partida.notas && <div className="notes">{partida.notas}</div>}
              <div id="order-fulfillment--item-grid" className="grid">
                <div className="col1 list-label">Solicitado</div>
                <div className="pill large selected div-center">
                  <div>
                    <span className="cantidad">
                      {partida.cantidadSolicitada}
                    </span>
                    <span className="unidad">{partida.unidad}</span>
                  </div>
                </div>
                <div className="col1 list-label ">Surtido</div>
                <div className="col2">
                  <QuantityEntry
                    value={surtido}
                    step={partida.intervaloCantidad}
                    onValueChanging={(value, e) => {
                      // Si esta disminuyendo, no valida
                      if (value < surtido) return;

                      // Si la diferencia entre solicitado y surtido es mayor al 100% rechaza
                      if (value > partida.cantidadSolicitada) {
                        let diferencia = value - partida.cantidadSolicitada;
                        if (diferencia > 0) {
                          let diferenciaPorcentaje =
                            diferencia / partida.cantidadSolicitada;
                          let toleranciaDiferenciaPorcentaje = 1;
                          if (partida.unidad.toLowerCase() === "pieza")
                            toleranciaDiferenciaPorcentaje = 0;
                          if (
                            diferenciaPorcentaje >
                            toleranciaDiferenciaPorcentaje
                          ) {
                            e.cancel = !window.confirm(
                              "Esta surtiendo mas del doble de lo solicitado. Desea continuar?"
                            );
                          }
                        }
                      }
                    }}
                    onValueChanged={(value) => {
                      if (value == "0") {
                        setSurtido("");
                        setKilosSurtidos("");
                        return;
                      }

                      value = parseFloat(value);

                      if (
                        partida.unidad.toLowerCase() === "pieza" &&
                        partida.pesoPorPieza > 0
                      )
                        setKilosSurtidos(partida.pesoPorPieza * value);
                      setSurtido(value);
                    }}
                    disabled={disabled}
                  />
                </div>
                {partida.unidad.toLowerCase() === "pieza" &&
                  partida.pesoPorPieza > 0 && (
                    <Fragment>
                      <div className="col1">Surtido (KG)</div>
                      <div className="col2">
                        <QuantityEntry
                          value={kilosSurtidos}
                          step={0.1}
                          onValueChanged={(value) => setKilosSurtidos(value)}
                          disabled={disabled}
                        />
                      </div>
                    </Fragment>
                  )}
                <div className="col1 list-label">
                  {surtido !== partida.cantidadSolicitada && (
                    <span>
                      {surtido > partida.cantidadSolicitada
                        ? "Excedente:"
                        : "Faltante:"}
                    </span>
                  )}
                </div>
                <div className="col2">
                  <div
                    className={
                      "pill large div-center " +
                      GetDiferenciaStyle(partida.cantidadSolicitada, surtido)
                    }
                  >
                    {partida.cantidadSolicitada - surtido !== 0 && (
                      <div>
                        <span className="cantidad">
                          {round(
                            Math.abs(partida.cantidadSolicitada - surtido),
                            2
                          )}
                        </span>
                        <span className="unidad">{partida.unidad}</span>
                      </div>
                    )}
                    {partida.cantidadSolicitada - surtido === 0 && (
                      <span>Completo!</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal--footer">
          <button
            className="btn primary"
            disabled={disabled}
            onClick={() => {
              partida.cantidadSurtida = surtido;
              partida.kilosSurtidos = kilosSurtidos;
              onUpdateCantidadSurtida(partida);
              onClose();
            }}
          >
            Guardar
          </button>
        </div>
      </div>
    </div>
  );
};

export default FulfillOrderPartidaDialog;
