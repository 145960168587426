import React from "react";

const PillOptions = ({ options, variant, selected, onSelect }) => {
  return (
    <div className={"pill-options " + variant}>
      {options.map((option) => (
        <div
          key={option}
          className={
            "pill--option " +
            (option === selected ? " selected " : " ") +
            variant
          }
          onClick={() => {
            selected = option;
            onSelect(option);
          }}
        >
          {option}
        </div>
      ))}
    </div>
  );
};

export default PillOptions;
