import { useState } from "react";

const CollapsiblePanel = ({ title, subtitle, subTitleClassName, children }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <div className="collapsiblePanel">
      <div className="collapsiblePanel--header">
        <div className="collapsiblePanel--titlebar">
          <div>{title}</div>
          <div className={subTitleClassName}>{subtitle}</div>
        </div>
        <div>
          <button
            className="btn rounded collapsiblePanel--button"
            onClick={() => setExpanded(!expanded)}
          >
            {expanded && <i className="fas fa-chevron-up" />}
            {!expanded && <i className="fas fa-chevron-down" />}
          </button>
        </div>
      </div>
      {expanded && <div className="collapsiblePanel--children">{children}</div>}
    </div>
  );
};

export default CollapsiblePanel;
