import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";

import NavBar from "../../utils/nav/NavBar";
import NavMenu from "../../utils/nav/NavMenu";
import PillOptions from "../../utils/pillOptions";
import Authorization from "../../utils/secure/Authorization";
import Search from "../../utils/search";
import Loading from "../../utils/loading";
import FulfillProducto from "./FulfillProducto";

import { GetProductosPorSurtir } from "../../../services/fulfillmentServices";
import { round } from "../../../services/mathServices";

import "../../../css/ordersFulfillment.css";
import { GetDiferenciaStyle } from "../../utils/utils";
import ScrollToTopButton from "../../utils/scrollToTopButton";
import PullToRefresh from "react-simple-pull-to-refresh";

const OrdersFulfillmentByProduct = () => {
  const [loading, setLoading] = useState(false);
  const [permissionChecked, setPermissionChecked] = useState(false);
  const [filtroPartidas, setFiltroPartidas] = useState("Todos");
  const [searchText, setSearchText] = useState("");
  const [productosPorSurtir, setProductosPorSurtir] = useState([]);
  const [currentProducto, setCurrentProducto] = useState();

  let query = new URLSearchParams(useLocation().search);

  useEffect(() => {
    cargarProductosPorSurtir();
  }, []);

  const cargarProductosPorSurtir = async () => {
    try {
      setLoading(true);
      let fecha = query.get("fecha");
      let productos = await GetProductosPorSurtir(fecha);

      setProductosPorSurtir(productos);
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
    }
  };

  const filterItems = () => {
    let rValue = [];
    switch (filtroPartidas) {
      case "Todos":
        rValue = productosPorSurtir;
        break;
      case "por Surtir":
        rValue = productosPorSurtir.filter((p) => p.surtido === 0);
        break;
      case "con Diferencia":
        rValue = productosPorSurtir.filter(
          (p) =>
            p.surtido > p.solicitado ||
            (p.surtido > 0 &&
              Math.abs((p.solicitado - p.surtido) / p.solicitado > 0.1))
        );
        break;
      default:
        return;
    }

    if (searchText !== "")
      rValue = rValue.filter(
        (p) =>
          p.descripcion.toLowerCase().includes(searchText) ||
          p.categoria.toLowerCase().includes(searchText)
      );

    return rValue;
  };

  const sortItems = (items) =>
    items.sort(
      (a, b) =>
        a.categoria.localeCompare(b.categoria) ||
        a.descripcion.localeCompare(b.descripcion)
    );

  const updateCantidadSurtida = async (producto, partida) => {
    let i = productosPorSurtir.findIndex(
      (x) => x.idProducto === producto.idProducto
    );
    if (i >= 0) productosPorSurtir[i] = producto;

    setProductosPorSurtir([...productosPorSurtir]);
  };

  if (currentProducto)
    return (
      <FulfillProducto
        fecha={query.get("fecha")}
        producto={currentProducto}
        onClose={() => setCurrentProducto(null)}
        onUpdateCantidadSurtida={updateCantidadSurtida}
      />
    );

  return (
    <div id="orders-fulfillment-by-product">
      {!permissionChecked && (
        <Authorization
          permission="Pedidos/Armado"
          onPermissionGranted={() => setPermissionChecked(true)}
        />
      )}

      <PullToRefresh onRefresh={() => cargarProductosPorSurtir()}>
        <ScrollToTopButton />
        <div className="page-header">
          <NavBar
            title="Preparación por producto"
            navButtonComponent={<NavMenu />}
          />

          <div className="bar single">
            <div id="orderFulfillment-filter">
              <PillOptions
                options={["Todos", "por Surtir", "con Diferencia"]}
                variant="primary"
                selected={filtroPartidas}
                onSelect={(option) => setFiltroPartidas(option)}
              />
            </div>
          </div>
        </div>
        <div className="page-content">
          <Search
            initialSearchText={searchText}
            onSearch={(searchText) => setSearchText(searchText.toLowerCase())}
          />
          <div className="page-list">
            {sortItems(filterItems()).map((p) => (
              <div
                key={p.idProducto}
                className="list-item"
                onClick={() => setCurrentProducto(p)}
              >
                <div id="orders-fulfillment-by-product--items">
                  <div className="col1">
                    <img className="thumbnail" src={p.imagenUrl} />
                  </div>
                  <div className="col2">
                    <div className="list-item--heading">{p.categoria}</div>
                    <div className="list-item--heading">{p.descripcion}</div>
                  </div>
                </div>
                <div id="orders-fulfillment-by-product--cantidades">
                  <div className="pill selected">
                    <div>Solicitado</div>
                    <div>
                      {p.solicitado} {p.unidad}
                    </div>
                  </div>
                  <div className="pill primary">
                    <div>Surtido</div>
                    <div>
                      {p.surtido} {p.unidad}
                    </div>
                  </div>
                  <div
                    className={
                      "pill  " + GetDiferenciaStyle(p.solicitado, p.surtido)
                    }
                  >
                    <div>Pendiente</div>
                    <div>
                      {p.surtido < p.solicitado && (
                        <span>
                          {Math.abs(round(p.solicitado - p.surtido, 2))}{" "}
                          {p.unidad}
                        </span>
                      )}
                      {p.surtido >= p.solicitado && <span>0</span>}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </PullToRefresh>
      {loading && <Loading />}
    </div>
  );
};

export default OrdersFulfillmentByProduct;
