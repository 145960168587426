import React, { useState, useEffect } from "react";
import Authorization from "../../utils/secure/Authorization";
import NavBar from "../../utils/nav/NavBar";
import NavMenu from "../../utils/nav/NavMenu";

import ButtonAdd from "../../utils/buttonAdd";

import { GetConceptosGastos } from "../../../services/gastosServices";
import UpdateConceptoGasto from "./updateConceptoGasto";

const ConceptosGastoPage = () => {
  const [permissionChecked, setPermissionChecked] = useState(false);
  const [permissionGranted, setPermissionGranted] = useState(false);

  const [conceptos, setConceptos] = useState([]);
  const [adding, setAdding] = useState(false);
  const [editingId, setEditingId] = useState(null);

  useEffect(() => {
    cargarDatos();
  }, []);

  const cargarDatos = async () => {
    try {
      let conceptos = await GetConceptosGastos();
      setConceptos(conceptos);
    } catch (error) {
      if (error.response) {
        alert(error.response.data);
      } else {
        alert(error.message);
      }
    }
  };

  if (!permissionChecked)
    return (
      <div id="gastos">
        <Authorization
          permission="Gastos/Conceptos"
          onPermissionGranted={() => {
            setPermissionChecked(true);
            setPermissionGranted(true);
          }}
        />
      </div>
    );

  if (permissionChecked && !permissionGranted) return <div></div>;

  if (adding)
    return (
      <UpdateConceptoGasto
        conceptoId={0}
        onClose={() => setAdding(false)}
        onSave={() => {
          setAdding(false);
          cargarDatos();
        }}
      />
    );

  if (editingId)
    return (
      <UpdateConceptoGasto
        conceptoId={editingId}
        onClose={() => setEditingId(null)}
        onSave={() => {
          setEditingId(null);
          cargarDatos();
        }}
      />
    );

  return (
    <div id="conceptos-gastos">
      <div className="page-header">
        <NavBar
          title="Gastos"
          navButtonComponent={<NavMenu />}
          toolbar={<ButtonAdd onClick={() => setAdding(true)} />}
        />
      </div>

      <div className="page-list margin-1">
        {conceptos.map((concepto, i) => (
          <div
            key={i}
            className="margin-bottom-1 border-bottom grid font-smaller"
            onClick={() => setEditingId(concepto.id)}
          >
            {concepto.nombre}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ConceptosGastoPage;
