import React from "react";

const Tabs = ({ tabs, selectedTab, onSelectedTabChanged }) => {
  return (
    <div className="tabs">
      {tabs.map((tab) => (
        <div
          key={tab}
          className={"tabs--tab" + (tab === selectedTab ? " selected" : " ")}
          onClick={() => onSelectedTabChanged(tab)}
        >
          {tab}
        </div>
      ))}
    </div>
  );
};

export default Tabs;
