import React from "react";

const ButtonSave = ({ onClick }) => {
  return (
    <button className="round-button shadow secondary" onClick={onClick}>
      <i className="fas fa-save"></i>
    </button>
  );
};

export default ButtonSave;
