import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import NavBar from "../../utils/nav/NavBar";
import NavMenu from "../../utils/nav/NavMenu";
import Loading from "../../utils/loading";
import moment from "moment";
import { round } from "../../../services/mathServices";

import "../../../css/purchase-receipt.css";

import { GetDiferenciaStyle } from "../../utils/utils";
import Authorization from "../../utils/secure/Authorization";
import { GetPurchaseReceipt } from "../../../services/purchaseServices";
import Search from "../../utils/search";
import PillOptions from "../../utils/pillOptions";
import PurchaseReceiptItem from "./purchase-receipt-item";
import { formatNumber } from "../../../services/stringServices";
import PurchaseReceiptSupplier from "./purchase-receipt-supplier";
import ScrollToTopButton from "../../utils/scrollToTopButton";
import ButtonAdd from "../../utils/buttonAdd";
import PullToRefresh from "react-simple-pull-to-refresh";

const PurchaseReceipt = () => {
  const [permissionChecked, setPermissionChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [compras, setCompras] = useState([]);
  const [filtroPartidas, setFiltroPartidas] = useState("Todos");
  const [editingItem, setEditingItem] = useState();
  const [showTotalesPorProveedor, setShowTotalesPorProveedor] = useState(false);

  let query = new URLSearchParams(useLocation().search);
  let fecha = query.get("fecha");
  useEffect(() => {
    cargar();
  }, []);
  const cargar = async () => {
    try {
      setLoading(true);
      if (!fecha) fecha = moment();

      let purchaseReceipt = await GetPurchaseReceipt(fecha);

      setCompras(purchaseReceipt);
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
    }
  };

  const filterItems = () => {
    let rValue = [];
    switch (filtroPartidas) {
      case "Todos":
        rValue = compras;
        break;
      case "por Recibir":
        rValue = compras.filter((p) => p.surtido === 0);
        break;
      case "con Diferencia":
        rValue = compras.filter(
          (p) =>
            p.surtido > p.solicitado ||
            (p.surtido > 0 && p.surtido < p.solicitado)
        );
        break;

      default:
        return;
    }

    if (searchText !== "")
      rValue = rValue.filter(
        (p) =>
          p.descripcion.toLowerCase().includes(searchText) ||
          p.proveedor.toLowerCase().includes(searchText)
      );

    return rValue;
  };

  const sortItems = (items) =>
    items.sort((a, b) => a.descripcion.localeCompare(b.descripcion));

  const totalRecibido = () => {
    let arr = filterItems();
    if (arr.length === 0) return 0;
    let suma = filterItems().reduce((a, b) => ({
      importeSurtido: round(a.importeSurtido, 2) + round(b.importeSurtido, 2),
    }));
    return suma.importeSurtido;
  };

  const updateItem = async (itemChanged) => {
    try {
      let index = compras.findIndex(
        (p) => p.ordenCompraPartidaId === itemChanged.ordenCompraPartidaId
      );
      compras[index] = itemChanged;
      setCompras([...compras]);
      setEditingItem(null);
    } catch (error) {
      alert(error);
    }
  };

  const addItem = async (itemAdded) => {
    try {
      compras.push(itemAdded);
      setCompras([...compras]);
      setEditingItem(null);
    } catch (error) {
      alert(error);
    }
  };

  return (
    <div id="purchase-receipt">
      {!permissionChecked && (
        <Authorization
          permission="Compras/RecepcionCompra"
          onPermissionGranted={() => setPermissionChecked(true)}
        />
      )}
      <ScrollToTopButton />
      <PullToRefresh onRefresh={cargar}>
        <div className="page-header">
          <NavBar
            title="Recepción de compras"
            navButtonComponent={<NavMenu />}
          />
          <div id="purchase-receipt--toolbar" className="bar single">
            <div
              className="col1"
              onClick={() =>
                setShowTotalesPorProveedor(!showTotalesPorProveedor)
              }
            >
              Total: ${formatNumber(totalRecibido(), 2)}
              <i
                id="purchase-receipt--showHideSuppliers"
                className={
                  !showTotalesPorProveedor
                    ? "fas fa-sort-down"
                    : "fas fa-sort-up"
                }
              ></i>
            </div>
            <div className="col2">
              <ButtonAdd
                onClick={() => {
                  setEditingItem({
                    isNewItem: true,
                    surtido: 0.0,
                    importeSurtido: 0.0,
                  });
                }}
              />
            </div>
          </div>
          {showTotalesPorProveedor && (
            <PurchaseReceiptSupplier
              compras={compras}
              onClose={() => setShowTotalesPorProveedor(false)}
            />
          )}
          <div className="bar single">
            <PillOptions
              options={["Todos", "por Recibir", "con Diferencia"]}
              variant="primary"
              selected={filtroPartidas}
              onSelect={(option) => setFiltroPartidas(option)}
            />
          </div>
        </div>

        <div className="page-content">
          <Search
            initialSearchText={searchText}
            onSearch={(searchText) => setSearchText(searchText.toLowerCase())}
          />

          <div className="page-list">
            {sortItems(filterItems()).map((item) => (
              <div
                key={item.ordenCompraPartidaId}
                className="purchaseReceipt--item list-item"
                onClick={() => setEditingItem(item)}
              >
                <div className="col1">
                  <img className="thumbnail" src={item.imageUrl} alt="" />
                </div>
                <div className="col2">
                  <div className="list-item--heading">{item.descripcion}</div>
                  Proveedor: <strong>{item.proveedor}</strong>
                  <div id="purchase-receipt--item-grid" className="grid">
                    <div className="col1">Solicitado</div>
                    <div className="col2 text-align-center">
                      <span className="cantidad">{item.solicitado} </span>
                      <span className="unidad">{item.unidad}</span>
                    </div>
                    <div className="col1">Surtido</div>
                    <div className="col2">
                      <div
                        className={
                          "pill large div-center " +
                          GetDiferenciaStyle(item.solicitado, item.surtido)
                        }
                      >
                        {item.surtido}
                      </div>
                    </div>
                    <div className="col1">Importe</div>
                    <div className="col2 text-align-center">
                      <span className="cantidad">
                        ${formatNumber(item.importeSurtido, 2)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </PullToRefresh>
      {loading && <Loading />}
      {editingItem && (
        <PurchaseReceiptItem
          item={editingItem}
          fecha={fecha}
          onClose={() => setEditingItem(null)}
          onUpdateComplete={(item) => updateItem(item)}
          onAddComplete={(item) => addItem(item)}
        />
      )}
    </div>
  );
};

export default PurchaseReceipt;
