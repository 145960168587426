import React, { useState } from "react";

const NavMenuItem = ({ option, selected, onSelected, children }) => {
  const [expanded, setExpanded] = useState(false);

  function menuItemClass() {
    let rValue = "menu-item";
    if (selected && selected.id === option.id) rValue += " selected";
    if (option.bottom) rValue += " bottom";
    return rValue;
  }

  if (children)
    return (
      <div className="menu-item submenu" onClick={() => setExpanded(!expanded)}>
        <div className="submenu--header">
          <div>
            <i className={option.iconClass + " menu-item-icon"} />
            {option.caption}
          </div>
          <button
            className="btn rounded"
            onClick={() => setExpanded(!expanded)}
          >
            {expanded && <i className="fas fa-chevron-up" />}
            {!expanded && <i className="fas fa-chevron-down" />}
          </button>
        </div>

        {expanded && <div className="submenu--body">{children}</div>}
      </div>
    );

  return (
    <div className={menuItemClass()} onClick={() => onSelected(option)}>
      <i className={option.iconClass + " menu-item-icon"} />
      {option.caption}
    </div>
  );
};

export default NavMenuItem;
