// Based on https://moderncss.dev/custom-select-styles-with-pure-css/

import React from "react";

import "../../css/select.css";

const Select = ({ id, items, value, onChange, itemKey, itemDisplay }) => {
  return (
    <div className="select" id={id}>
      <select value={value} onChange={onChange}>
        {items.map((item) => (
          <option key={itemKey(item)} value={itemKey(item)}>
            {itemDisplay(item)}
          </option>
        ))}
      </select>
      <span className="select--focus"></span>
    </div>
  );
};

export default Select;
