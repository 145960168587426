import webAPIServices from "./webAPIServices";
import moment from "moment";

export async function GetEstadoResultados(rangoFechas) {
  const appsvc = new webAPIServices();

  let desde = moment(rangoFechas.Desde).format("YYYY-MM-DD");
  let hasta = moment(rangoFechas.Hasta).format("YYYY-MM-DD");

  let requestUrl = `/backoffice/estadoresultados?desde=${desde}&hasta=${hasta}`;

  return appsvc.Get(requestUrl);
}
