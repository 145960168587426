import React, { useState, useEffect } from "react";

import { useLocation } from "react-router";

import Authorization from "../../utils/secure/Authorization";
import NavBar from "../../utils/nav/NavBar";
import NavMenu from "../../utils/nav/NavMenu";

import Select from "../../utils/select";
import Loading from "../../utils/loading";
import Search from "../../utils/search";
import { GetRouteOrdersToFullfill } from "../../../services/fulfillmentServices";
import FulfillOrder from "./FulfillOrder";
import ScrollToTopButton from "../../utils/scrollToTopButton";

import "../../../css/ordersFulfillment.css";

import { GetRutas } from "../../../services/rutasServices";

import PullToRefresh from "react-simple-pull-to-refresh";

const OrdersFulfillment = () => {
  const [permissionChecked, setPermissionChecked] = useState(false);
  const [rutas, setRutas] = useState([]);
  const [pedidos, setPedidos] = useState([]);
  const [selectedRuta, setSelectedRuta] = useState();
  const [loading, setLoading] = useState(false);
  const [pedidoSeleccionado, setPedidoSeleccionado] = useState();
  const [searchText, setSearchText] = useState("");

  let query = new URLSearchParams(useLocation().search);

  useEffect(() => {
    async function cargarRutas() {
      try {
        setLoading(true);
        let fecha = query.get("fecha");
        let rutas = await GetRutas(fecha);
        setRutas(rutas);
        if (rutas.length > 0) selectRuta(rutas[0]);
      } catch (error) {
        alert(error);
      } finally {
        setLoading(false);
      }
    }
    cargarRutas();
  }, []);

  async function selectRuta(ruta) {
    try {
      console.log("loading");
      setLoading(true);
      let pedidos = await GetRouteOrdersToFullfill(ruta.id);
      setPedidos(pedidos);
      setSelectedRuta(ruta);
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleOnSelectedRutaChanged(rutaId) {
    let ruta = rutas.find((r) => r.id === parseInt(rutaId));
    if (ruta) selectRuta(ruta);
  }

  function getPillStyleEstatusPedido(pedido) {
    let className = "pill ";
    switch (pedido.estatusArmado) {
      case "Listo":
        if (pedido.porcentajeArmado >= 100) className += " primary";
        else className += " warning";
        break;
      case "por Surtir":
        className += " selected";
        break;
      default:
        className += " danger";
        break;
    }
    return className;
  }

  async function fulfillOrderClosed(pedidoId) {
    setPedidoSeleccionado();

    try {
      setLoading(true);

      let currentPedidosIndex = pedidos.findIndex((p) => p.id === pedidoId);
      let newPedidos = await GetRouteOrdersToFullfill(selectedRuta.id);
      let newPedidoIndex = newPedidos.findIndex((p) => p.id === pedidoId);

      pedidos[currentPedidosIndex] = newPedidos[newPedidoIndex];

      setPedidos([...pedidos]);
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
    }
  }

  if (pedidoSeleccionado)
    return (
      <FulfillOrder
        pedidoId={pedidoSeleccionado.id}
        onClose={fulfillOrderClosed}
      />
    );

  return (
    <div id="orders-fulfillment">
      {!permissionChecked && (
        <Authorization
          permission="Pedidos/Armado"
          onPermissionGranted={() => setPermissionChecked(true)}
        />
      )}
      <PullToRefresh onRefresh={() => selectRuta(selectedRuta)}>
        <ScrollToTopButton />
        <div className="page-header">
          <NavBar
            title="Preparación de pedidos"
            navButtonComponent={<NavMenu />}
          />
          <div className="bar single">
            <Select
              id="selectRuta"
              items={rutas}
              value={selectedRuta && selectedRuta.id}
              onChange={(e) => handleOnSelectedRutaChanged(e.target.value)}
              itemKey={(item) => item.id}
              itemDisplay={(ruta) =>
                `R${ruta.numero} ${ruta.nombre} - ${ruta.chofer.nombre}`
              }
            />
          </div>
        </div>

        <div className="page-content">
          <Search
            initialSearchText={searchText}
            onSearch={(searchText) => setSearchText(searchText.toLowerCase())}
          />

          <div className="page-list">
            {pedidos
              .filter(
                (p) =>
                  searchText === "" ||
                  (searchText !== "" &&
                    p.nombre.toLowerCase().includes(searchText)) ||
                  p.id === searchText
              )
              .sort((a, b) =>
                a.ruta > b.ruta ? -1 : a.ruta === b.ruta ? 0 : 1
              )
              .map((pedido) => (
                <div
                  className="list-item list-item--focusable"
                  key={pedido.id}
                  onClick={() => setPedidoSeleccionado(pedido)}
                >
                  <div className="list-item--heading">{pedido.nombre}</div>
                  <div className="list-item--content div-between">
                    <div>{pedido.id}</div>
                    <div>R{pedido.ruta}</div>
                  </div>
                  {pedido.notas && (
                    <div className="list-item--notes">{pedido.notas}</div>
                  )}
                  <div className="orders-fulfillment-list-item-footer">
                    <div className={getPillStyleEstatusPedido(pedido)}>
                      {pedido.estatusArmado === "Listo" && (
                        <i className="fas fa-check-circle check--green" />
                      )}
                      <span>
                        {pedido.porcentajeArmado > 0 &&
                          pedido.porcentajeArmado + "%"}{" "}
                        {pedido.estatusArmado}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </PullToRefresh>
      {loading && <Loading />}
    </div>
  );
};

export default OrdersFulfillment;
