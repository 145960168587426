import React, { useState, useEffect } from "react";

import "./products.css";

import Authorization from "../../utils/secure/Authorization";
import NavBar from "../../utils/nav/NavBar";
import NavMenu from "../../utils/nav/NavMenu";
import Search from "../../utils/search";
import Loading from "../../utils/loading";

import PullToRefresh from "react-simple-pull-to-refresh";
import { GetProductos } from "../../../services/productosServices";
import PaginateButtons from "../../utils/paginate";
import { formatNumber } from "../../../services/stringServices";
import EditProduct from "./editProduct";
import ButtonAdd from "../../utils/buttonAdd";
import PillOptions from "../../utils/pillOptions";
import { fil } from "date-fns/locale";

const Products = () => {
  const [permissionChecked, setPermissionChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  const [productos, setProductos] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [numPages, setNumPages] = useState(0);
  const [filteredItems, setFilteredItems] = useState([]);
  const [filtroEstatus, setFiltroEstatus] = useState("Activos");

  const [editingProduct, setEditingProduct] = useState();

  const pageLength = 15;

  useEffect(() => {
    cargar();
  }, []);

  const cargar = async () => {
    try {
      setLoading(true);
      let productos = await GetProductos();
      setProductos(productos);
      filterItems(productos);
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (search) => {
    setSearchText(search.toLowerCase());
    setCurrentPage(0);
    filterItems(productos, search, 0);
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
    filterItems(undefined, undefined, selectedPage);
  };

  const handleProductEdited = (product) => {
    setEditingProduct();

    if (!product) return;

    if (!editingProduct.id) {
      cargar();
      return;
    }

    let index = productos.findIndex((x) => x.id === product.id);
    if (index > -1) {
      productos[index] = product;
      setProductos([...productos]);
      filterItems(productos);
    }
  };

  const filterItems = (
    items = productos,
    search = searchText,
    currPage = currentPage,
    filterStatus = filtroEstatus
  ) => {
    let searchLowerCase = search.toLocaleLowerCase();

    let filtered = items.filter(
      (x) =>
        x.descripcion.toLowerCase().includes(searchLowerCase) ||
        x.categoriaProducto.nombre.toLowerCase().includes(searchLowerCase) ||
        x.proveedor.nombre.toLowerCase().includes(searchLowerCase)
    );

    switch (filterStatus) {
      case "Activos":
        filtered = filtered.filter((x) => x.activo && !x.agotado);
        break;
      case "Inactivos":
        filtered = filtered.filter((x) => !x.activo);
        break;
      case "Agotados":
        filtered = filtered.filter((x) => x.agotado);
        break;
    }

    let numPages = Math.floor(filtered.length / pageLength) - 1;
    setNumPages(numPages);

    let startIndex = currPage * pageLength;
    let endIndex = startIndex + pageLength;
    let page = filtered.slice(startIndex, endIndex);

    setFilteredItems(page);
  };

  if (editingProduct)
    return (
      <EditProduct
        producto={editingProduct}
        onClose={() => handleProductEdited(null)}
        onSave={(product) => handleProductEdited(product)}
      />
    );

  return (
    <div id="products">
      {!permissionChecked && (
        <Authorization
          permission="Catálogos/Productos/ABC"
          onPermissionGranted={() => setPermissionChecked(true)}
        />
      )}
      <PullToRefresh onRefresh={cargar}>
        <div className="page-header">
          <NavBar
            title="Productos"
            navButtonComponent={<NavMenu />}
            toolbar={
              !loading && (
                <ButtonAdd
                  onClick={() =>
                    setEditingProduct({ intervalo: 1, activo: true })
                  }
                />
              )
            }
          />
          <div className="bar single products--filtro-status">
            <PillOptions
              options={["Activos", "Inactivos", "Agotados", "Todos"]}
              variant="primary"
              selected={filtroEstatus}
              onSelect={(option) => {
                setFiltroEstatus(option);
                filterItems(undefined, undefined, undefined, option);
              }}
            />
          </div>
        </div>
        <div className="page-content">
          <Search
            initialSearchText={searchText}
            onSearch={(searchText) => handleSearch(searchText)}
          />
          <div className="page-list">
            {filteredItems.map((producto) => (
              <div
                key={producto.id}
                className={
                  "list-item grid" +
                  (!producto.activo || producto.agotado ? " disabled" : "")
                }
                onClick={() => setEditingProduct(producto)}
              >
                <div className="col1 div-center products--image ">
                  <img className="thumbnail" src={producto.imagenUrl} />
                  {!producto.activo && (
                    <div className="pill danger">Inactivo</div>
                  )}
                  {producto.agotado && (
                    <div className="pill warning">Agotado</div>
                  )}
                </div>
                <div className="col2 products--info">
                  <div>
                    <div className="list-item--heading">
                      {producto.descripcion}
                    </div>
                    <div className="list-item--sub-heading">
                      {producto.categoriaProducto.nombre}
                    </div>
                    <div className="list-item--sub-heading">
                      {producto.proveedor.nombre}
                    </div>
                  </div>
                  <div className="div-right products--precio">
                    $ {formatNumber(producto.precio, 2)} {producto.unidad}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </PullToRefresh>
      {!loading && (
        <PaginateButtons
          numPages={numPages}
          currentPage={currentPage}
          onPageChange={(pageNumber) => handlePageChange(pageNumber)}
        />
      )}
      {loading && <Loading />}
    </div>
  );
};

export default Products;
