import webAPIServices from "./webAPIServices";

export async function GetProveedores() {
  const appsvc = new webAPIServices();

  let requestUrl = `/backoffice/proveedores`;

  return appsvc.Get(requestUrl);
}

export async function PutProveedor(proveedor) {
  const appsvc = new webAPIServices();

  let requestUrl = `/backoffice/proveedores`;

  return appsvc.Put(requestUrl, proveedor);
}

export async function PostProveedor(proveedor) {
  const appsvc = new webAPIServices();

  let requestUrl = `/backoffice/proveedores`;

  return appsvc.Post(requestUrl, proveedor);
}
