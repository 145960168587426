import { Logout } from "./secureServices";

export function GetBackofficeUserSession() {
  let sessionId = sessionStorage.backofficeUserSessionId;
  let expiration = sessionStorage.backofficeUserSessionExpires;
  let userName = sessionStorage.backofficeUserName;

  let expires = Date.now();
  if (expiration) expires = new Date(expiration);

  if (sessionId && expires > Date.now())
    return {
      id: sessionId,
      expires: expiration,
      userName: userName,
    };

  return null;
}

export function SetBackofficeUserSession(session) {
  sessionStorage.backofficeUserSessionId = session.id;
  sessionStorage.backofficeUserSessionExpires = session.expires;
  sessionStorage.backofficeUserName = session.userName;
}

export async function LogoutBackofficeUserSession() {
  let sessionId = GetBackofficeUserSession();
  sessionStorage.removeItem("backofficeUserSessionId");
  sessionStorage.removeItem("backofficeUserSessionExpires");
  sessionStorage.removeItem("backofficeUserName");
  if (sessionId) await Logout(sessionId.id);
}
