import React, { useState, useEffect } from "react";
import Authorization from "../../utils/secure/Authorization";
import moment from "moment";

import NavBar from "../../utils/nav/NavBar";
import NavMenu from "../../utils/nav/NavMenu";
import PullToRefresh from "react-simple-pull-to-refresh";
import Search from "../../utils/search";
import Loading from "../../utils/loading";

import { formatNumber } from "../../../services/stringServices";

import "../../../css/cxc.css";
import { GetCxC } from "../../../services/cxcServices";
import CxCPayment from "./cxcPayment";

const CxC = () => {
  const [permissionChecked, setPermissionChecked] = useState(false);
  const [permissionGranted, setPermissionGranted] = useState(false);

  const [loading, setLoading] = useState(false);

  const [searchText] = useState("");
  const [cxc, setCxc] = useState([]);
  const [filtered, setFiltered] = useState(cxc);

  const [editingCxC, setEditingCxC] = useState(null);

  useEffect(() => {
    cargarDatos();
  }, []);

  const cargarDatos = async () => {
    try {
      setLoading(true);
      let cxc = await GetCxC();
      setCxc(cxc);
      setFiltered(cxc);
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
    }
  };

  function handleSearch(text) {
    text = text.toLocaleLowerCase();
    let r = cxc.filter((x) => x.nombre.toLocaleLowerCase().includes(text));
    setFiltered(r);
  }

  if (editingCxC)
    return (
      <CxCPayment
        cxc={editingCxC}
        onClose={() => setEditingCxC(null)}
        onSave={() => {
          setEditingCxC(null);
          cargarDatos();
        }}
      />
    );

  if (!permissionChecked)
    return (
      <div id="cxc">
        <Authorization
          permission="CxC/Lista"
          onPermissionGranted={() => {
            setPermissionChecked(true);
            setPermissionGranted(true);
          }}
        />
      </div>
    );

  if (permissionChecked && !permissionGranted) return <div></div>;

  if (permissionChecked && permissionGranted)
    return (
      <div id="cxc">
        <PullToRefresh onRefresh={() => cargarDatos()}>
          <div className="page-header">
            <NavBar
              title="Cuentas por Cobrar"
              navButtonComponent={<NavMenu />}
            />
            <div className="bar single">
              <div className="align-right ">
                <span className="margin-right-1">({filtered.length})</span>
                <span>Total: $</span>
                <span className="font-small">
                  {formatNumber(
                    filtered.reduce(
                      (previous, current) => previous + current.saldo,
                      0
                    ),
                    2
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="margin-1">
            <Search
              initialSearchText={searchText}
              onSearch={(searchText) => handleSearch(searchText)}
            />
          </div>

          <div className="page-list">
            {filtered.map((cxc, i) => (
              <div key={i} className="margin-bottom-1 border-bottom grid">
                <div className="col1">
                  <div className="div-left">
                    <span className="margin-right-1 font-muted">Pedido</span>
                    <span className="font-bold margin-right-1">{cxc.id}</span>
                    <span className="margin-right-1 font-muted"> - </span>
                    <span className="font-bold">
                      {moment(cxc.fecha).format("DD/MM/YY")}
                    </span>
                  </div>
                  <div>{cxc.nombre}</div>
                  <div>
                    <span className="margin-right-1 font-muted">Saldo</span>
                    <span className="font-bold align-right">
                      $ {formatNumber(cxc.saldo, 2)}
                    </span>
                  </div>
                </div>
                <div className="col2 align-right">
                  <button
                    className="btn primary"
                    onClick={() => setEditingCxC(cxc)}
                  >
                    Pago
                  </button>
                </div>
              </div>
            ))}
            <div className="align-right font-small">
              Total:{" $"}
              {formatNumber(
                filtered.reduce(
                  (previous, current) => previous + current.saldo,
                  0
                ),
                2
              )}
            </div>
          </div>
          {loading && <Loading />}
        </PullToRefresh>
      </div>
    );
};

export default CxC;
