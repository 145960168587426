import webAPIServices from "./webAPIServices";

export async function GetSalesDashboard(rangoFechas) {
  const appsvc = new webAPIServices();
  let requestUrl = `/backoffice/dashboards/ventas?desde=${encodeURIComponent(
    rangoFechas.Desde.toISOString()
  )}&hasta=${encodeURIComponent(rangoFechas.Hasta.toISOString())}`;

  return appsvc.Get(requestUrl);
}

export async function GetSalesOrdersDashboard(rangoFechas) {
  const appsvc = new webAPIServices();
  let requestUrl = `/backoffice/dashboards/ventas/pedidos?desde=${encodeURIComponent(
    rangoFechas.Desde.toISOString()
  )}&hasta=${encodeURIComponent(rangoFechas.Hasta.toISOString())}`;

  return appsvc.Get(requestUrl);
}

export async function DownloadAcumuladoVentasProducto(rangoFechas) {
  const appsvc = new webAPIServices();
  let requestUrl = `/backoffice/dashboards/ventas/acumulado-producto?desde=${encodeURIComponent(
    rangoFechas.Desde.toISOString()
  )}&hasta=${encodeURIComponent(rangoFechas.Hasta.toISOString())}`;

  return appsvc.GetBlob(requestUrl);
}
