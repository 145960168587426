import React, { useState, useEffect } from "react";
import NavBar from "../../utils/nav/NavBar";
import NavMenu from "../../utils/nav/NavMenu";
import Authorization from "../../utils/secure/Authorization";

import "../../../css/settings.css";
import {
  GetSettings,
  UpdateSettings,
} from "../../../services/settingsServices";
import Loading from "../../utils/loading";

const Settings = () => {
  const [permissionChecked, setPermissionChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState();

  useEffect(() => {
    async function cargarPreferencias() {
      try {
        setLoading(true);
        let settings = await GetSettings();
        setSettings(settings);
      } catch (error) {
        alert(error);
      } finally {
        setLoading(false);
      }
    }
    cargarPreferencias();
  }, []);

  const guardar = async () => {
    try {
      setLoading(true);
      await UpdateSettings(settings);
      setSettings(settings);
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div id="settings">
      {!permissionChecked && (
        <Authorization
          permission="Preferencias"
          onPermissionGranted={() => setPermissionChecked(true)}
        />
      )}
      <div className="page-header">
        <NavBar
          title={
            <div className="col-2">
              Preferencias
              <button className="btn secondary small" onClick={guardar}>
                GUARDAR
              </button>
            </div>
          }
          navButtonComponent={<NavMenu />}
        />
      </div>
      {settings && (
        <div className="page-content">
          <h2>Impresora</h2>
          <div className="box form">
            <div className="field-group">
              <label htmlFor="ip">Dirección IP:</label>
              <input
                type="text"
                id="ip"
                placeholder="000.000.000.000"
                value={settings.ipImpresoraTicketPedido}
                onChange={(e) => {
                  settings.ipImpresoraTicketPedido = e.currentTarget.value;
                  setSettings({ ...settings });
                }}
              />
            </div>
            <div className="field-group">
              <label htmlFor="port">Puerto:</label>
              <input
                type="text"
                id="port"
                placeholder="8008"
                value={settings.puertoImpresoraTicketPedido}
                onChange={(e) => {
                  settings.puertoImpresoraTicketPedido = e.currentTarget.value;
                  setSettings({ ...settings });
                }}
              />
            </div>
          </div>
          <h2>Ticket de pedido</h2>
          <div className="box form">
            <div className="field-group">
              <label htmlFor="leyenda">Leyenda:</label>
              <textarea
                id="leyenda"
                rows="3"
                value={settings.notasTicketPedido}
                onChange={(e) => {
                  settings.notasTicketPedido = e.currentTarget.value;
                  setSettings({ ...settings });
                }}
              />
            </div>
          </div>
          <h2>Costos de envío</h2>
          <div className="box form">
            <div className="field-group">
              <label htmlFor="costoEnvioInicial">Primer envío ruta:</label>
              <input
                type="number"
                pattern="[0-9]*"
                name="costoEnvioInicial"
                id="costoEnvioInicial"
                value={settings.costoEnvioInicial}
                onChange={(e) => {
                  settings.costoEnvioInicial = e.currentTarget.value;
                  setSettings({ ...settings });
                }}
              />
            </div>
            <div className="field-group">
              <label htmlFor="costoEnvioOmision">Envío subsecuente:</label>
              <input
                type="number"
                pattern="[0-9]*"
                name="costoEnvioOmision"
                id="costoEnvioOmision"
                value={settings.costoEnvioOmision}
                onChange={(e) => {
                  settings.costoEnvioOmision = e.currentTarget.value;
                  setSettings({ ...settings });
                }}
              />
            </div>
          </div>
          <div>
            <small>Build: 230218.01</small>
          </div>
        </div>
      )}
      {loading && <Loading />}
    </div>
  );
};

export default Settings;
