import React from "react";

const PaginateButtons = ({ numPages, currentPage, onPageChange }) => {
  return (
    <div className="paginate-buttons">
      <button
        className="secondary"
        disabled={numPages === 0 || currentPage === 0}
        onClick={() => onPageChange(currentPage - 1)}
      >
        <i className="fas fa-angle-left"></i>
      </button>

      <button
        className="secondary paginate-buttons--current"
        disabled={numPages <= 0}
      >
        {currentPage + 1} / {numPages + 1}
      </button>

      <button
        className="secondary"
        disabled={numPages === 0 || currentPage >= numPages}
        onClick={() => onPageChange(currentPage + 1)}
      >
        <i className="fas fa-angle-right"></i>
      </button>
    </div>
  );
};

export default PaginateButtons;
