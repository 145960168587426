import React, { useState, useEffect } from "react";
import Authorization from "../../utils/secure/Authorization";
import NavBar from "../../utils/nav/NavBar";
import NavMenu from "../../utils/nav/NavMenu";
import Loading from "../../utils/loading";
import ButtonAdd from "../../utils/buttonAdd";

import "./inhabiles.css";

import { GetDiasInhabiles } from "../../../services/diasInhabilesServices";
import moment from "moment";
import EditInhabil from "./editInhabil";

const Inhabiles = () => {
  const [permissionChecked, setPermissionChecked] = useState();
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  const [editingItem, setEditingItem] = useState();

  // Change the permission to check
  const permission = "Catálogos/Usuarios/Perfiles";

  useEffect(() => {
    cargar();
  }, []);

  const cargar = async () => {
    try {
      setLoading(true);
      let inhabiles = await GetDiasInhabiles();
      setItems(inhabiles);
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
    }
  };

  if (editingItem)
    return (
      <EditInhabil
        inhabil={editingItem}
        onClose={() => setEditingItem()}
        onSave={(inhabil) => {
          setEditingItem();
          cargar();
        }}
      />
    );

  return (
    <div id="inhabiles">
      {!permissionChecked && (
        <Authorization
          permission={permission}
          onPermissionGranted={() => setPermissionChecked(true)}
        />
      )}
      <div className="page-header">
        <NavBar
          title="Días inhábiles"
          navButtonComponent={<NavMenu />}
          toolbar={!loading && <ButtonAdd onClick={() => setEditingItem({})} />}
        />
      </div>
      <div className="page-content">
        <div className="page-list">
          <div className="list-header">
            <div className="align-right">Fecha inhabil</div>
            <div className="align-right">Fecha de entrega</div>
            <div className="align-center">Mensaje</div>
          </div>
          {items.map((item) => (
            <div
              key={item.id}
              className="list-item"
              onClick={() => setEditingItem(item)}
            >
              <div className="align-right">
                {moment(item.fechaInhabil).format("DD/MM/YY")}
              </div>
              <div className="align-right">
                {moment(item.fechaEntrega).format("DD/MM/YY")}
              </div>
              <div className="align-left">{item.mensaje}</div>
            </div>
          ))}
        </div>
      </div>
      {loading && <Loading />}
    </div>
  );
};

export default Inhabiles;
