import React, { useState, useEffect } from "react";

import NavBar from "../../utils/nav/NavBar";
import Select from "../../utils/select";
import moment from "moment";
import { formatNumber } from "../../../services/stringServices";
import { GetFormasPago, RegistrarPago } from "../../../services/cxcServices";
import { toNumber } from "lodash";

const CxCPayment = ({ cxc, onClose, onSave }) => {
  const [formasPago, setFormasPago] = useState([]);
  const [formaPago, setFormaPago] = useState();
  const [referencia, setReferencia] = useState("");
  const [monto, setMonto] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    async function Init() {
      try {
        let formasPago = await GetFormasPago();
        setFormasPago(formasPago);
        setFormaPago(formasPago[0]);
      } catch (error) {
        alert(error);
      }
    }
    Init();
  }, []);

  function handleChangeFormaPago(formaPagoId) {
    let f = formasPago.find((x) => x.id == formaPagoId);
    if (!f) return;

    setFormaPago(f);
  }

  const registrarPago = async () => {
    setErrorMessage("");
    try {
      let montoPago = toNumber(monto);
      if (montoPago <= 0) {
        setErrorMessage("Escriba el monto del pago");
        return;
      }

      if (montoPago > cxc.saldo) {
        setErrorMessage("El monto del pago no puede ser mayor al saldo");
        return;
      }

      if (!formaPago.efectivo && !referencia) {
        setErrorMessage("Escriba la referencia de la forma de pago");
        return;
      }

      await RegistrarPago({
        pedidoId: cxc.id,
        monto: montoPago,
        formaPagoId: formaPago.id,
        referencia: referencia,
      });

      onSave();
    } catch (error) {
      if (error.response) {
        setErrorMessage(error.response.data);
      } else {
        setErrorMessage(error.message);
      }
    }
  };

  return (
    <div>
      <div className="overlay">
        <div className="modal">
          <div id="cxcPayment-modal" className="modal--content">
            <div className="modal-header">
              <NavBar
                title="Registrar pago"
                navButtonComponent={
                  <i
                    className="fas fa-times nav-button-back"
                    onClick={onClose}
                  />
                }
              />
            </div>
            <div className="modal-body">
              <div className="card">
                <div className="form form--vertical">
                  <div className="margin-bottom-1">
                    <div className="div-left margin-bottom-1">
                      <span className="margin-right-1 font-muted">Pedido</span>
                      <span className="font-bold margin-right-1">{cxc.id}</span>
                      <span className="margin-right-1 font-muted"> - </span>
                      <span className="font-bold">
                        {moment(cxc.fecha).format("DD/MM/YY")}
                      </span>
                    </div>
                    <div className="margin-bottom-1">{cxc.nombre}</div>
                    <div>
                      <span className="margin-right-1 font-muted">Saldo</span>
                      <span className="font-bold align-right">
                        $ {formatNumber(cxc.saldo, 2)}
                      </span>
                    </div>
                  </div>

                  <label className="label">Forma de pago:</label>
                  {formaPago && (
                    <Select
                      value={formaPago.id}
                      items={formasPago}
                      itemKey={(item) => item.id}
                      itemDisplay={(item) => item.nombre}
                      onChange={(e) =>
                        handleChangeFormaPago(e.currentTarget.value)
                      }
                    />
                  )}

                  {formaPago && !formaPago.efectivo && (
                    <div>
                      <label>Referencia:</label>
                      <input
                        type="text"
                        value={referencia}
                        onChange={(e) => setReferencia(e.currentTarget.value)}
                      />
                    </div>
                  )}
                  <label className="label">Monto:</label>
                  <input
                    type="number"
                    pattern="[0-9]*"
                    inputMode="decimal"
                    value={monto}
                    onChange={(e) => setMonto(e.currentTarget.value)}
                  />

                  <div className="margin-top-2 margin-bottom-1 div-right">
                    <button
                      className="btn primary"
                      onClick={() => registrarPago()}
                    >
                      Guardar
                    </button>
                  </div>
                  <div className="form--error">{errorMessage}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CxCPayment;
