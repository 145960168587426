import webAPIServices from "./webAPIServices";

export async function GetPerfiles() {
  const appsvc = new webAPIServices();

  let requestUrl = `/controlacceso/perfil`;

  return appsvc.Get(requestUrl);
}

export async function GetUsuariosPerfil(perfilId) {
  const appsvc = new webAPIServices();

  let requestUrl = `/controlacceso/perfil/${perfilId}/usuarios`;

  return appsvc.Get(requestUrl);
}

export async function GetPermisos() {
  const appsvc = new webAPIServices();

  let requestUrl = `/controlacceso/permiso`;

  return appsvc.Get(requestUrl);
}

export async function GetPermisosPerfil(perfilId) {
  const appsvc = new webAPIServices();

  let requestUrl = `/controlacceso/perfil/${perfilId}/permiso`;

  return appsvc.Get(requestUrl);
}

export async function PostPerfil(perfil) {
  const appsvc = new webAPIServices();

  let requestUrl = `/controlacceso/perfil`;

  return appsvc.Post(requestUrl, perfil);
}
export async function PutPerfil(perfil) {
  const appsvc = new webAPIServices();

  let requestUrl = `/controlacceso/perfil`;

  return appsvc.Put(requestUrl, perfil);
}

export async function PatchPerfilPermisos(perfilId, permisosAsignados) {
  const appsvc = new webAPIServices();

  let requestUrl = `/controlacceso/perfil/${perfilId}/permiso`;

  return appsvc.Patch(requestUrl, permisosAsignados);
}
export async function PostUser(user) {
  const appsvc = new webAPIServices();

  let requestUrl = `/controlacceso/usuario`;

  return appsvc.Post(requestUrl, user);
}

export async function PutUser(user) {
  const appsvc = new webAPIServices();

  let requestUrl = `/controlacceso/usuario`;

  return appsvc.Put(requestUrl, user);
}
