import React, { useState, useEffect } from "react";
import { GetSalesOrdersDashboard } from "../../../services/dashboardServices";
import NavBar from "../../utils/nav/NavBar";
import Search from "../../utils/search";
import Loading from "../../utils/loading";
import NumberFormat from "react-number-format";
import { formatShortDate } from "../../../helpers/datesHelper";

const OrdersDashboard = ({ rangoFechas, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [sortInfo, setSortInfo] = useState({
    column: "venta",
    ascending: true,
  });
  const [pageInfo, setPageInfo] = useState({
    currentPageIndex: 0,
    currentPageData: [],
    pageCount: 0,
  });
  const PAGE_SIZE = 10;

  const [dashboard, setDashboard] = useState([]);

  useEffect(() => {
    cargarDatos();
  }, []);

  const cargarDatos = async () => {
    setLoading(true);
    try {
      let dashboard = await GetSalesOrdersDashboard(rangoFechas);
      setDashboard(dashboard);
      sortBy("venta", dashboard);
    } finally {
      setLoading(false);
    }
  };

  /* Sorting */
  const sortBy = (columnName, data) => {
    setLoading(true);

    let ascending = sortInfo.ascending;
    let sortColumn = sortInfo.column;
    if (!data) data = dashboard;

    if (sortColumn === columnName) {
      ascending = !ascending;
    } else {
      ascending = true;
      sortColumn = columnName;
    }

    let mSorted = data.sort((a, b) => sortOrder(a, b, sortColumn, ascending));

    loadPage(mSorted, 0, searchText);

    setDashboard(mSorted);

    pageInfo.currentPageIndex = 0;

    setPageInfo({ ...pageInfo });
    setSortInfo({ column: sortColumn, ascending: ascending });

    setLoading(false);
  };

  const sortOrder = (a, b, sortColumn, sortAscending) => {
    switch (sortColumn) {
      case "nombre":
        if (sortAscending)
          return a.nombre.toLowerCase() > b.nombre.toLowerCase() ? 1 : -1;
        else return a.nombre.toLowerCase() < b.nombre.toLowerCase() ? 1 : -1;
      case "noPedidos":
      case "venta":
      case "costo":
      case "utilidad":
      case "ventaPromedio":
      case "fechaPrimerPedido":
      case "fechaUltimoPedido":
        if (sortAscending) return a[sortColumn] > b[sortColumn] ? 1 : -1;
        else return a[sortColumn] < b[sortColumn] ? 1 : -1;
      default:
        return 0;
    }
  };

  const getSortIcon = (columnName) => {
    if (columnName === sortInfo.column) {
      if (sortInfo.ascending) return "list-header--sorted-asc fas fa-sort-up";
      else return "list-header--sorted-asc fas fa-sort-down";
    } else {
      return "list-header--sortable fas fa-sort";
    }
  };

  /* Paging */
  const loadPage = (data, pageIndex, filter) => {
    let startIndex = pageIndex * PAGE_SIZE;
    let endIndex = startIndex + PAGE_SIZE;
    if (endIndex > data.length - 1) endIndex = data.length;
    let filtered = data;
    if (filter !== "") {
      filtered = data.filter((p) => p.nombre.toLowerCase().includes(filter));
    }
    pageInfo.currentPageData = filtered.slice(startIndex, endIndex);
    pageInfo.currentPageIndex = pageIndex;
    pageInfo.pageCount = Math.ceil(filtered.length / PAGE_SIZE);
    setPageInfo({ ...pageInfo });
  };

  const nextPage = () => {
    if (pageInfo.currentPageIndex >= pageInfo.pageCount) return;

    pageInfo.currentPageIndex += 1;
    setPageInfo({ ...pageInfo });
    loadPage(dashboard, pageInfo.currentPageIndex, searchText);
  };

  const previousPage = () => {
    if (pageInfo.currentPageIndex <= 0) return;

    pageInfo.currentPageIndex -= 1;
    setPageInfo({ ...pageInfo });
    loadPage(dashboard, pageInfo.currentPageIndex, searchText);
  };

  const firstPage = () => {
    pageInfo.currentPageIndex = 0;
    setPageInfo({ ...pageInfo });
    loadPage(dashboard, pageInfo.currentPageIndex, searchText);
  };

  const lastPage = () => {
    pageInfo.currentPageIndex = pageInfo.pageCount - 1;
    setPageInfo({ ...pageInfo });
    loadPage(dashboard, pageInfo.currentPageIndex, searchText);
  };

  /* filter */
  const filter = (text) => {
    setSearchText(text);
    if (text === "") loadPage(dashboard, 0, text);
    else loadPage(dashboard, 0, text.toLowerCase());
  };

  return (
    <div id="ordersDashboard">
      <div className="page-header">
        <NavBar
          title={"Ventas / Pedidos "}
          navButtonComponent={
            <i
              className="fas fa-arrow-left nav-button-back"
              onClick={() => onClose()}
            />
          }
        />
        <div className="bar single">Pedidos del {rangoFechas.toString()}</div>
      </div>
      <div className="page">
        <div className="page-content">
          <div id="ordersDashboard--orders-list" className="card">
            <Search onSearch={(searchText) => filter(searchText)} />
            <div className="grid">
              <div className="list-header" onClick={() => sortBy("nombre")}>
                <i className={getSortIcon("nombre")} />
                Cliente
              </div>
              <div
                className="list-header align-right"
                onClick={() => sortBy("noPedidos")}
              >
                <i className={getSortIcon("noPedidos")} />
                Pedidos
              </div>
              <div
                className="list-header align-right"
                onClick={() => sortBy("venta")}
              >
                <i className={getSortIcon("venta")} />
                Venta
              </div>
              <div
                className="list-header align-right"
                onClick={() => sortBy("costo")}
              >
                <i className={getSortIcon("costo")} />
                Costo
              </div>
              <div
                className="list-header align-right"
                onClick={() => sortBy("utilidad")}
              >
                <i className={getSortIcon("utilidad")} />
                Utilidad
              </div>
              <div
                className="list-header align-right"
                onClick={() => sortBy("ventaPromedio")}
              >
                <i className={getSortIcon("ventaPromedio")} />
                Venta promedio
              </div>
              <div
                className="list-header align-right"
                onClick={() => sortBy("fechaPrimerPedido")}
              >
                <i className={getSortIcon("fechaPrimerPedido")} />
                Primer pedido
              </div>
              <div
                className="list-header align-right"
                onClick={() => sortBy("fechaUltimoPedido")}
              >
                <i className={getSortIcon("fechaUltimoPedido")} />
                Ultimo pedido
              </div>
              {pageInfo.currentPageData.map((x) => (
                <React.Fragment key={x.eMail}>
                  <div className="list-row">{x.nombre}</div>
                  <div className="list-row align-right">{x.noPedidos}</div>
                  <div className="list-row align-right">
                    <NumberFormat
                      value={x.venta}
                      displayType="text"
                      decimalScale="2"
                      fixedDecimalScale="2"
                      thousandSeparator
                    />
                  </div>
                  <div className="list-row align-right">
                    <NumberFormat
                      value={x.costo}
                      displayType="text"
                      decimalScale="2"
                      fixedDecimalScale="2"
                      thousandSeparator
                    />
                  </div>
                  <div className="list-row align-right">
                    <NumberFormat
                      value={x.utilidad}
                      displayType="text"
                      decimalScale="2"
                      fixedDecimalScale="2"
                      thousandSeparator
                    />
                  </div>
                  <div className="list-row align-right">
                    <NumberFormat
                      value={x.ventaPromedio}
                      displayType="text"
                      decimalScale="2"
                      fixedDecimalScale="2"
                      thousandSeparator
                    />
                  </div>
                  <div className="list-row align-right">
                    {formatShortDate(x.fechaPrimerPedido)}
                  </div>
                  <div className="list-row align-right">
                    {x.fechaUltimoPedido &&
                      formatShortDate(x.fechaUltimoPedido)}
                  </div>
                </React.Fragment>
              ))}
            </div>
            <div className="align-right">
              <button
                className="btn rounded primary"
                disabled={pageInfo.currentPageIndex === 0}
                onClick={firstPage}
              >
                <i className="fas fa-step-backward"></i>
              </button>
              <button
                className="btn rounded primary"
                disabled={pageInfo.currentPageIndex === 0}
                onClick={previousPage}
              >
                <i className="fas fa-backward"></i>
              </button>
              <button
                className="btn rounded primary"
                disabled={pageInfo.currentPageIndex >= pageInfo.pageCount - 1}
                onClick={nextPage}
              >
                <i className="fas fa-forward"></i>
              </button>
              <button
                className="btn rounded primary"
                disabled={pageInfo.currentPageIndex >= pageInfo.pageCount - 1}
                onClick={lastPage}
              >
                <i className="fas fa-fast-forward"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <Loading show={loading} />
    </div>
  );
};

export default OrdersDashboard;
