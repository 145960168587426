import webAPIServices from "./webAPIServices";

export async function GetCuentasContablesPagos() {
  const appsvc = new webAPIServices();

  let requestUrl = `/backoffice/cuentascontables/SAT/101,102`;

  return appsvc.Get(requestUrl);
}

export async function GetCuentasContablesGastos() {
  const appsvc = new webAPIServices();

  let requestUrl = `/backoffice/cuentascontables?grupos=3,5`;

  return appsvc.Get(requestUrl);
}

export async function GetCuentasContables() {
  const appsvc = new webAPIServices();

  let requestUrl = `/backoffice/cuentascontables`;

  return appsvc.Get(requestUrl);
}

export async function AddCuenta(cuenta) {
  const appsvc = new webAPIServices();
  let requestUrl = `/backoffice/cuentascontables`;

  return appsvc.Post(requestUrl, cuenta);
}

export async function EditCuenta(cuenta) {
  const appsvc = new webAPIServices();
  let requestUrl = `/backoffice/cuentascontables`;

  return appsvc.Put(requestUrl, cuenta);
}
