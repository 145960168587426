import React, { useState } from "react";

import { LogoutBackofficeUserSession } from "../../../services/sessionServices";
import NavMenuItem from "./NavMenuItem";
import { useHistory } from "react-router-dom";
import MenuHeaderUser from "./MenuHeaderUser";

import "../../../css/nav.css";

const NavMenu = ({ selectedOption, userName }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [currentOption, setCurrentOption] = useState(selectedOption);

  const history = useHistory();

  function selectOption(option) {
    setCurrentOption(option);
    setShowMenu(false);
    if (option.targetUrl) history.push(option.targetUrl);
  }
  return (
    <div id="navMenu">
      <a
        href="/"
        onClick={(e) => {
          e.preventDefault();
          setShowMenu(!showMenu);
        }}
      >
        <i id="nav--hamburger" className="fas fa-bars"></i>
      </a>
      {showMenu && (
        <div id="mainMenu">
          <div className="overlay" onClick={() => setShowMenu(false)}></div>
          <div id="menu">
            <div id="menu-header">
              <MenuHeaderUser />
              <div
                id="menu-header-logout"
                onClick={async () => {
                  try {
                    await LogoutBackofficeUserSession();
                  } finally {
                    window.location.reload();
                  }
                }}
              >
                <i className="fas fa-sign-out-alt"></i>
              </div>
            </div>
            <img
              id="menu-header-divider"
              className="flip-vertical"
              src={`${process.env.PUBLIC_URL}/img/waves-divider.svg`}
              alt=""
            />
            <div id="menu-options">
              <NavMenuItem
                option={{
                  id: "Pedidos",
                  caption: "Preparación de Pedidos",
                  iconClass: "fas fa-cash-register",
                }}
              >
                <NavMenuItem
                  option={{
                    id: "order-fulfill",
                    caption: "por Ruta",
                    iconClass: "fas fa-truck",
                    targetUrl: "/orders-fulfillment",
                  }}
                  selected={currentOption}
                  onSelected={(e) => selectOption(e)}
                />
                <NavMenuItem
                  option={{
                    id: "order-fulfill-by-product",
                    caption: "por Producto",
                    iconClass: "fas fa-box-open",
                    targetUrl: "/orders-fulfillment-by-product",
                  }}
                  selected={currentOption}
                  onSelected={(e) => selectOption(e)}
                />
              </NavMenuItem>

              <NavMenuItem
                option={{
                  id: "kickoff-route",
                  caption: "Envío de rutas",
                  iconClass: "fas fa-truck",
                  targetUrl: "/kickoff-routes",
                }}
                selected={currentOption}
                onSelected={(e) => selectOption(e)}
              />

              <NavMenuItem
                option={{
                  id: "cxc",
                  caption: "Cuentas x Cobrar",
                  iconClass: "fas fa-file-invoice-dollar",
                  targetUrl: "/cxc",
                }}
                selected={currentOption}
                onSelected={(e) => selectOption(e)}
              />

              <NavMenuItem
                option={{
                  id: "compras",
                  caption: "Compras",
                  iconClass: "fa-solid fa-cart-shopping",
                }}
              >
                <NavMenuItem
                  option={{
                    id: "recepcion-compra",
                    caption: "Recepción de compras",
                    iconClass: "fas fa-boxes",
                    targetUrl: "/purchase-receipt",
                  }}
                  selected={currentOption}
                  onSelected={(e) => selectOption(e)}
                />

                <NavMenuItem
                  option={{
                    id: "gastos",
                    caption: "Registro de gastos",
                    iconClass: "fas fa-money-bill-wave",
                    targetUrl: "/gastos",
                  }}
                  selected={currentOption}
                  onSelected={(e) => selectOption(e)}
                />
              </NavMenuItem>

              <NavMenuItem
                option={{
                  id: "reportes",
                  caption: "Reportes",
                  iconClass: "fas fa-print",
                }}
              >
                <NavMenuItem
                  option={{
                    id: "sales",
                    caption: "Ventas",
                    iconClass: "fas fa-cash-register",
                    targetUrl: "/sales",
                  }}
                  selected={currentOption}
                  onSelected={(e) => selectOption(e)}
                />

                <NavMenuItem
                  option={{
                    id: "stocks",
                    caption: "Existencias",
                    iconClass: "fas fa-list-ol",
                    targetUrl: "/stocks",
                  }}
                  selected={currentOption}
                  onSelected={(e) => selectOption(e)}
                />

                <NavMenuItem
                  option={{
                    id: "profitloss",
                    caption: "Estado de resultados",
                    iconClass: "fas fa-chart-line",
                    targetUrl: "/profitloss",
                  }}
                  selected={currentOption}
                  onSelected={(e) => selectOption(e)}
                />
              </NavMenuItem>

              <NavMenuItem
                option={{
                  id: "catalogos",
                  caption: "Catálogos",
                  iconClass: "fas fa-list",
                }}
              >
                <NavMenuItem
                  option={{
                    id: "products",
                    caption: "Productos",
                    iconClass: "fas fa-barcode",
                    targetUrl: "/products",
                  }}
                  selected={currentOption}
                  onSelected={(e) => selectOption(e)}
                />

                <NavMenuItem
                  option={{
                    id: "proveedores",
                    caption: "Proveedores",
                    iconClass: "fas fa-barcode",
                    targetUrl: "/proveedores",
                  }}
                  selected={currentOption}
                  onSelected={(e) => selectOption(e)}
                />

                <NavMenuItem
                  option={{
                    id: "conceptos-gastos",
                    caption: "Conceptos de gastos",
                    iconClass: "fas fa-list",
                    targetUrl: "/conceptos-gastos",
                  }}
                  selected={currentOption}
                  onSelected={(e) => selectOption(e)}
                />

                <NavMenuItem
                  option={{
                    id: "cuentas",
                    caption: "Cuentas",
                    iconClass: "fas fa-list",
                    targetUrl: "/cuentas",
                  }}
                  selected={currentOption}
                  onSelected={(e) => selectOption(e)}
                />

                <NavMenuItem
                  option={{
                    id: "inhabiles",
                    caption: "Días inhábiles",
                    iconClass: "fas fa-calendar",
                    targetUrl: "/inhabiles",
                  }}
                  selected={currentOption}
                  onSelected={(e) => selectOption(e)}
                />

                <NavMenuItem
                  option={{
                    id: "perfiles",
                    caption: "Perfiles y usuarios",
                    iconClass: "fas fa-users",
                    targetUrl: "/user-profiles",
                  }}
                  selected={currentOption}
                  onSelected={(e) => selectOption(e)}
                />

                <NavMenuItem
                  option={{
                    id: "codigosPostales",
                    caption: "Códigos postales",
                    iconClass: "fas fa-map-signs",
                    targetUrl: "/zip-codes",
                  }}
                  selected={currentOption}
                  onSelected={(e) => selectOption(e)}
                />
              </NavMenuItem>

              <NavMenuItem
                option={{
                  id: "settings",
                  caption: "Preferencias",
                  iconClass: "fas fa-cog",
                  targetUrl: "/settings",
                }}
                selected={{ currentOption }}
                onSelected={(e) => selectOption(e)}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NavMenu;
