import React, { useState, useEffect } from "react";

import { GetBackofficeUserSession } from "../../../services/sessionServices";

import Login from "./Login";

const SecureArea = ({ children }) => {
  const [currentSession, setCurrentSession] = useState();

  useEffect(() => {
    let backofficeUserSession = GetBackofficeUserSession();
    if (backofficeUserSession) setCurrentSession(backofficeUserSession);
  }, []);

  if (currentSession)
    return (
      <div>
        {React.cloneElement(children, {
          sessionId: currentSession.id,
          userName: currentSession.userName,
        })}
      </div>
    );

  return <Login onLoggedIn={(session) => setCurrentSession(session)} />;
};

export default SecureArea;
