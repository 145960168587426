import React, { useState, useEffect } from "react";

const ScrollToTopButton = ({ scrollOffset = 300 }) => {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scorlled upto given distance
  const toggleVisibility = () => {
    if (window.pageYOffset > scrollOffset) setIsVisible(true);
    else setIsVisible(false);
  };

  // Set the top cordinate to 0
  // make scrolling smooth
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <div>
      {isVisible && (
        <div
          className="floating-button floating-button--center primary scroll-to-top"
          onClick={scrollToTop}
        >
          <i className="fas fa-arrow-up"></i>
        </div>
      )}
    </div>
  );
};

export default ScrollToTopButton;
