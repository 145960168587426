import webAPIServices from "./webAPIServices";

export async function GetSettings() {
  const appsvc = new webAPIServices();

  let requestUrl = `/backoffice/Preferencias`;

  return appsvc.Get(requestUrl);
}

export async function UpdateSettings(settings) {
  const appsvc = new webAPIServices();

  let requestUrl = `/backoffice/Preferencias`;

  return appsvc.Put(requestUrl, settings);
}
