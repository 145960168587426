import React from "react";

const Loading = ({ show = true }) => {
  if (!show) return <div />;
  return (
    <div className="overlay">
      <div className="spinner warning"></div>
    </div>
  );
};

export default Loading;
