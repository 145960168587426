import { useEffect, useState } from "react";
import PullToRefresh from "react-simple-pull-to-refresh";
import {
  GetFechaDesde,
  GetFechaHasta,
  GetPrimerDiaDelMes,
  RangoFechas,
} from "../../../helpers/datesHelper";
import { GetEstadoResultados } from "../../../services/estadosFinancierosServices";
import { formatNumber } from "../../../services/stringServices";
import CollapsiblePanel from "../../utils/collapsiblePanel";
import DateRangeLink from "../../utils/dateRangeLink";
import Loading from "../../utils/loading";
import NavBar from "../../utils/nav/NavBar";
import NavMenu from "../../utils/nav/NavMenu";
import Authorization from "../../utils/secure/Authorization";

const EstadoResultadosPage = () => {
  const [permissionChecked, setPermissionChecked] = useState(false);
  const [permissionGranted, setPermissionGranted] = useState(false);

  const [rangoFechas, setRangoFechas] = useState({});
  const [loading, setLoading] = useState(false);

  const [reporte, setReporte] = useState([]);

  useEffect(() => {
    if (!rangoFechas.Desde) {
      let rango = new RangoFechas(
        GetFechaDesde(GetPrimerDiaDelMes()),
        GetFechaHasta(new Date())
      );
      setRangoFechas(rango);
      cargarDatos(rango);
    }
  }, []);

  const cargarDatos = async (rangoFechas) => {
    setRangoFechas(rangoFechas);
    try {
      setLoading(true);

      let reporte = await GetEstadoResultados(rangoFechas);
      if (!reporte.exitoso) {
        alert(reporte.mensajeError);
        return;
      }
      setReporte(reporte.renglones);

      console.log(reporte);
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
    }
  };

  if (!permissionChecked)
    return (
      <div>
        <Authorization
          permission="Reportes/Estado resultados"
          onPermissionGranted={() => {
            setPermissionChecked(true);
            setPermissionGranted(true);
          }}
        />
      </div>
    );

  if (permissionChecked && !permissionGranted) return <div></div>;

  const totalVentas = 0;

  const costoVentas = 0;

  const utilidadBruta = totalVentas - costoVentas;

  const totalGastos = 0;

  const utilidadNeta = utilidadBruta - totalGastos;

  if (!reporte) return <div>Cargando...</div>;

  return (
    <div>
      <PullToRefresh onRefresh={() => cargarDatos()}>
        <div className="page-header">
          <NavBar
            title="Estado de resultados"
            navButtonComponent={<NavMenu />}
          />

          <div className="bar single margin-top-1">
            <DateRangeLink
              prefix="Resultados del "
              rangoFechas={rangoFechas}
              onChange={(rango) => cargarDatos(rango)}
            />
          </div>
        </div>
        <div className="padding-1">
          {reporte.map((r, i) => (
            <div key={i}>
              <CollapsiblePanel
                title={
                  r.monto < 0 && r.titulo.includes("Utilidad")
                    ? r.titulo.replace("Utilidad", "Pérdida")
                    : r.titulo
                }
                subtitle={formatNumber(r.monto, 0)}
                subTitleClassName={r.monto < 0 ? "danger-fore" : ""}
              >
                <div>
                  {r.renglones.map((s, x) => (
                    <div key={x} className="edoResultados--content">
                      <div className="edoResultados--content--title">
                        {s.titulo}
                      </div>
                      <div className="edoResultados--content--monto">
                        {formatNumber(s.monto, 0)}
                      </div>
                      <div className="edoResultados--content--porcentaje">
                        {formatNumber(s.porcentaje, 0)}%
                      </div>
                    </div>
                  ))}
                </div>
              </CollapsiblePanel>
            </div>
          ))}
        </div>

        {loading && <Loading />}
      </PullToRefresh>
    </div>
  );
};

export default EstadoResultadosPage;
