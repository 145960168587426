import React, { useState, useEffect } from "react";
import { Calendar } from "react-date-range";
import * as locales from "react-date-range/dist/locale";
import NavBar from "../../utils/nav/NavBar";
import ButtonSave from "../../utils/buttonSave";

import moment from "moment";
import {
  PostDiaInhabil,
  PutDiaInhabil,
} from "../../../services/diasInhabilesServices";

const EditInhabil = ({ inhabil, onSave, onClose }) => {
  const [fechaInhabil, setFechaInhabil] = useState();
  const [fechaEntrega, setFechaEntrega] = useState();
  const [mensaje, setMensaje] = useState();
  useEffect(() => {
    if (!inhabil.fechaInhabil) {
      let fecha = new Date();
      setFechaInhabil(fecha);
      setFechaEntrega(fecha);
    } else {
      setFechaInhabil(inhabil.fechaInhabil);
      setFechaEntrega(inhabil.fechaEntrega);
      setMensaje(inhabil.mensaje);
    }
  }, []);
  const handleSave = async () => {
    if (fechaEntrega < fechaInhabil) {
      alert("La fecha de entrega no puede ser menor a la fecha inhabil");
      return;
    }
    try {
      inhabil.fechaInhabil = fechaInhabil;
      inhabil.fechaEntrega = fechaEntrega;
      inhabil.mensaje = mensaje;
      if (!inhabil.id) await PostDiaInhabil(inhabil);
      else await PutDiaInhabil(inhabil);
      onSave(inhabil);
    } catch (error) {
      alert(error);
    }
  };

  return (
    <div id="edit-inhabil">
      <div className="page-header">
        <NavBar
          title={!inhabil.id ? "Nueva fecha inhabil" : "Editar fecha inhabil"}
          navButtonComponent={
            <i
              className="fas fa-arrow-left nav-button-back"
              onClick={() => onClose()}
            />
          }
          toolbar={<ButtonSave onClick={() => handleSave()} />}
        />
      </div>
      <div className="page--content">
        <div className="card">
          <div className="form form--vertical">
            <label htmlFor="">Fecha inhábil:</label>
            <input
              type="date"
              value={moment(fechaInhabil).format("YYYY-MM-DD")}
              onChange={(e) => setFechaInhabil(e.currentTarget.value)}
            />

            <label htmlFor="">Fecha de entrega:</label>
            <input
              type="date"
              value={moment(fechaEntrega).format("YYYY-MM-DD")}
              onChange={(e) => setFechaEntrega(e.currentTarget.value)}
            />

            <label htmlFor="">Mensaje:</label>
            <textarea
              className="mensaje"
              id=""
              rows="10"
              value={mensaje}
              onChange={(e) => setMensaje(e.currentTarget.value)}
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditInhabil;
