import React, { useState, useEffect } from "react";
import Authorization from "../../utils/secure/Authorization";
import NavBar from "../../utils/nav/NavBar";
import NavMenu from "../../utils/nav/NavMenu";
import Loading from "../../utils/loading";
import { GetPerfiles } from "../../../services/usersServices";
import ButtonAdd from "../../utils/buttonAdd";
import EditProfile from "./editprofile";

import "./userprofiles.css";
import PullToRefresh from "react-simple-pull-to-refresh";

const UserProfiles = () => {
  const [permissionChecked, setPermissionChecked] = useState();
  const [loading, setLoading] = useState(false);
  const [perfiles, setPerfiles] = useState([]);
  const [editingPerfil, setEditingPerfil] = useState();

  useEffect(() => {
    cargar();
  }, []);

  const cargar = async () => {
    try {
      setLoading(true);
      let p = await GetPerfiles();
      setPerfiles(p);
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
    }
  };

  if (editingPerfil)
    return (
      <EditProfile
        perfil={editingPerfil}
        onClose={() => setEditingPerfil(null)}
        onSave={(perfil) => {
          setEditingPerfil(null);
          cargar();
        }}
      />
    );
  return (
    <div id="user-profiles">
      {!permissionChecked && (
        <Authorization
          permission="Catálogos/Usuarios/Perfiles"
          onPermissionGranted={() => setPermissionChecked(true)}
        />
      )}

      <PullToRefresh onRefresh={cargar}>
        <div className="page-header">
          <NavBar
            title="Perfiles"
            navButtonComponent={<NavMenu />}
            toolbar={
              !loading && <ButtonAdd onClick={() => setEditingPerfil({})} />
            }
          />
        </div>

        <div className="page-content">
          <div className="page-list">
            {perfiles.map((perfil) => (
              <div
                key={perfil.id}
                className="list-item"
                onClick={() => setEditingPerfil(perfil)}
              >
                {perfil.nombre}
              </div>
            ))}
          </div>
        </div>
      </PullToRefresh>
      {loading && <Loading />}
    </div>
  );
};

export default UserProfiles;
