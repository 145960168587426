import React, { useEffect, useState } from "react";
import { HasPermission } from "../../../services/secureServices";
import { GetBackofficeUserSession } from "../../../services/sessionServices";
import Loading from "../loading";

const Authorization = ({ permission, onPermissionGranted }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function checkPermission() {
      let session = GetBackofficeUserSession();
      if (!session) {
        window.location.href = "/";
        return;
      }

      try {
        var response = await HasPermission(session.id, permission);
        if (response !== "") {
          setLoading(false);
          alert(response);
          window.location.href = "/";
          return;
        }

        setLoading(false);
        if (onPermissionGranted) onPermissionGranted();
      } catch (error) {
        setLoading(false);
        alert(error);
      }
    }
    checkPermission();
  }, [permission]);
  if (loading) return <Loading />;
  else return <div />;
};

export default Authorization;
