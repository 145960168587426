import React, { useEffect, useState } from "react";
import {
  GetOrderToFulfill,
  PutOrderItemFulfillment,
  SetOrderCompleted,
  SetOrderToBeFulfilled,
} from "../../../services/fulfillmentServices";
import Loading from "../../utils/loading";
import NavBar from "../../utils/nav/NavBar";
import PillOptions from "../../utils/pillOptions";

import Search from "../../utils/search";
import CompleteOrderFulfillment from "./completeOrderFulfillment";
import { round } from "../../../services/mathServices";
import { printOrderFulfill } from "./printOrderFulfill";
import FulfillOrderPartida from "./FulfillOrderPartida";
import ScrollToTopButton from "../../utils/scrollToTopButton";

const FulfillOrder = ({ pedidoId, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [pedido, setPedido] = useState();
  const [filtroPartidas, setFiltroPartidas] = useState("Todos");
  const [searchText, setSearchText] = useState("");
  const [completing, setCompleting] = useState(false);
  const [puedeCompletar, setPuedeCompletar] = useState(true);

  useEffect(() => {
    const cargarPedido = async () => {
      try {
        setLoading(true);
        let order = await GetOrderToFulfill(pedidoId);
        setPedido(order);
        setLoading(false);
      } catch (error) {
        alert(error);
        onClose(pedidoId);
      }
    };
    cargarPedido();
  }, []);

  const filterItems = () => {
    let rValue = [];
    switch (filtroPartidas) {
      case "Todos":
        rValue = pedido.partidas;
        break;
      case "por Surtir":
        rValue = pedido.partidas.filter((p) => p.cantidadSurtida === 0);
        break;
      case "con Diferencia":
        rValue = pedido.partidas.filter(
          (p) =>
            p.cantidadSurtida > 0 &&
            round(Math.abs(p.cantidadPendiente) / p.cantidadSolicitada, 2) > 0.1
        );
        break;
      default:
        return;
    }

    if (searchText !== "")
      rValue = rValue.filter(
        (p) =>
          p.descripcion.toLowerCase().includes(searchText) ||
          p.categoria.toLowerCase().includes(searchText)
      );

    return rValue;
  };

  const sortItems = (items) =>
    items.sort(
      (a, b) =>
        a.categoria.localeCompare(b.categoria) ||
        a.descripcion.localeCompare(b.descripcion)
    );

  const updateCantidadSurtida = async (partida) => {
    try {
      setPuedeCompletar(false);
      await PutOrderItemFulfillment(
        pedido.id,
        partida.id,
        partida.cantidadSurtida,
        partida.kilosSurtidos
      );
      partida.cantidadPendiente = round(
        partida.cantidadSolicitada - partida.cantidadSurtida,
        2
      );
      setPedido({ ...pedido });
    } catch (error) {
      alert(error);
    } finally {
      setPuedeCompletar(true);
    }
  };

  const orderCompleted = async (cajas) => {
    setCompleting(false);
    setLoading(true);
    try {
      let newStatus = await SetOrderCompleted(pedido.id);
      if (newStatus === "porEnviar") {
        let order = await GetOrderToFulfill(pedidoId);
        printTicket(cajas, order);
      }

      // Regresa a la pagina anterior
      onClose(pedido.id);
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
    }
  };

  let printer = null;

  const printTicket = (cajas, pedidoAImprimir) => {
    let afterPrintingDelegate = null;
    // Temporalmente no imprimira cajas al final
    cajas = 0;
    if (cajas) {
      afterPrintingDelegate = () => printLabel(cajas);
    }
    if (!pedidoAImprimir) pedidoAImprimir = pedido;
    if (!printer) printer = new printOrderFulfill(pedidoAImprimir);
    printer.PrintTicket(afterPrintingDelegate);
  };

  const printLabel = (cajas) => {
    if (!cajas) cajas = 1;
    if (!printer) printer = new printOrderFulfill(pedido, cajas);
    printer.PrintLabel(cajas);
  };

  const setPorEnviar = async () => {
    if (!window.confirm("¿ Desea marcarlo como 'por Surtir'?")) return;
    setLoading(true);
    try {
      pedido.estatus = await SetOrderToBeFulfilled(pedido.id);
      setPedido({ ...pedido });
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
    }
  };

  if (!pedido) return <Loading />;

  return (
    <div id="order-fulfillment">
      <ScrollToTopButton />
      <div className="page-header">
        <NavBar
          title={"Surtir pedido " + pedido.id}
          navButtonComponent={
            <i
              className="fas fa-arrow-left nav-button-back"
              onClick={() => onClose(pedido.id)}
            />
          }
        />
        <div className="bar single">
          <div id="orderFulfillment-header">
            <div className="navbar-title">{pedido.nombre}</div>
            <div className="navbar-title">{pedido.ruta}</div>
          </div>
        </div>
        {pedido.notas && (
          <div className="bar single">
            <div id="orderFulfillment-notes">{pedido.notas}</div>
          </div>
        )}
        <div id="orderFulfillment-toolbar" className="bar single">
          <div id="orderFulfillment-filter">
            <PillOptions
              options={["Todos", "por Surtir", "con Diferencia"]}
              variant="primary"
              selected={filtroPartidas}
              onSelect={(option) => setFiltroPartidas(option)}
            />
          </div>
          <div style={{ alignSelf: "flex-end", paddingTop: "1rem" }}>
            {pedido.estatus === "porEnviar" && (
              <div>
                <button className="btn warning" onClick={() => setPorEnviar()}>
                  <span>por Surtir</span>
                </button>
                <button className="btn icon primary" onClick={printTicket}>
                  <i className="fas fa-print"></i>
                </button>
                <button
                  className="btn icon primary"
                  onClick={() => printLabel(1)}
                >
                  <i className="fas fa-tag"></i>
                </button>
              </div>
            )}
            {pedido.estatus === "porSurtir" && (
              <div>
                <button
                  className="btn success "
                  disabled={!puedeCompletar}
                  onClick={() => {
                    orderCompleted(1);
                    // setCompleting(true);
                  }}
                >
                  <span>Listo</span>
                </button>
                <button
                  className="btn icon primary"
                  onClick={() => printLabel(1)}
                >
                  <i className="fas fa-tag"></i>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="page">
        <div className="page-content">
          <Search
            onSearch={(searchText) => setSearchText(searchText.toLowerCase())}
          />
          <div id="order-fulfillment--items" className="page-list">
            {sortItems(filterItems()).map((partida) => (
              <FulfillOrderPartida
                key={partida.id}
                partida={partida}
                pedido={pedido}
                onUpdateCantidadSurtida={updateCantidadSurtida}
              />
            ))}
          </div>
        </div>
      </div>
      {loading && <Loading />}
      {completing && (
        <CompleteOrderFulfillment
          pedido={pedido}
          onClose={() => setCompleting(false)}
          onComplete={orderCompleted}
        />
      )}
    </div>
  );
};

export default FulfillOrder;
