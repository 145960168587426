import React, { useState, useEffect } from "react";
import { GetCategoriasProductos } from "../../../services/categoriasServices";
import { GetProveedores } from "../../../services/proveedoresServices";
import {
  CalcularPrecioVenta,
  GetUnidadesMedida,
  PutProducto,
  PostProducto,
  UploadImagenProducto,
} from "../../../services/productosServices";

import AutoComplete from "../../utils/autoComplete";
import NavBar from "../../utils/nav/NavBar";
import Select from "../../utils/select";
import Checkbox from "../../utils/checkBox";

const EditProduct = ({ producto, onClose, onSave }) => {
  const [edit, setEdit] = useState({ ...producto });
  const [categorias, setCategorias] = useState([]);
  const [proveedores, setProveedores] = useState([]);
  const [unidadesMedida, setUnidadesMedida] = useState([]);
  const [imageToUpload, setImageToUpload] = useState();

  useEffect(() => {
    const cargarCatalogos = async () => {
      let categorias = await GetCategoriasProductos();
      let proveedores = await GetProveedores();
      let unidades = await GetUnidadesMedida();

      // En altas agrega item para seleccionar
      if (!producto.id) {
        categorias.splice(0, 0, { id: 0, nombre: "" });
        proveedores.splice(0, 0, { id: 0, nombre: "" });
        unidades.splice(0, 0, "");
      }

      setCategorias(categorias);
      setProveedores(proveedores);
      setUnidadesMedida(unidades);
    };
    window.scrollTo({ top: 0 });
    cargarCatalogos();
  }, []);

  const handleChangeCategoria = (id) => {
    edit.categoriaProductoId = Number(id);
    let index = categorias.findIndex((x) => x.id === edit.categoriaProductoId);
    edit.categoria = categorias[index];
    setEdit({ ...edit });
  };

  const handleChangeProveedor = (proveedor) => {
    if (proveedor) {
      edit.proveedorId = proveedor.id;
      edit.proveedor = proveedor;
    } else {
      edit.proveedorId = null;
      edit.proveedor = null;
    }
    setEdit({ ...edit });
  };

  const handleUploadImage = (e) => {
    let file = e.target.files[0];
    setImageToUpload(file);

    // Shows the image
    var reader = new FileReader();
    var imgtag = document.getElementById("productImage");
    imgtag.title = file.name;

    reader.onload = function (event) {
      imgtag.src = event.target.result;
    };

    reader.readAsDataURL(file);
  };

  const validate = () => {
    if (!edit.descripcion) {
      alert("Escriba la descripción del producto");
      return false;
    }
    if (!edit.categoriaProductoId) {
      alert("Seleccione la categoría del producto");
      return false;
    }
    if (!edit.proveedorId) {
      alert("Seleccione al proveedor del producto");
      return false;
    }
    if (!edit.unidad) {
      alert("Escriba la unidad de medida del producto");
      return false;
    }
    if (edit.intervalo <= 0) {
      alert("Escriba el intervalo del producto");
      return false;
    }
    if (edit.costoUnitario <= 0) {
      alert("Escriba el costo del producto");
      return false;
    }
    if (edit.margen <= 0) {
      alert("Indique el margen del producto");
      return false;
    }
    if (edit.montoSobrePrecio1 < 0) {
      alert("El monto de sobreprecio 1 es invalido");
      return;
    }
    if (edit.montoSobrePrecio2 < 0) {
      alert("El monto de sobreprecio 2 es invalido");
      return;
    }

    return true;
  };

  const handleSave = async () => {
    if (!validate()) return;

    try {
      if (edit.id) await PutProducto(edit);
      else {
        let newProducto = { ...edit };
        newProducto.proveedor = null;
        newProducto.categoria = null;
        edit.id = await PostProducto(newProducto);
      }

      if (imageToUpload != null)
        edit.imagenUrl = await UploadImagenProducto(edit.id, imageToUpload);

      window.scrollTo({ top: 0 });
      onSave(edit);
    } catch (error) {
      alert(error);
    }
  };

  return (
    <div id="edit-product">
      <div className="page-header">
        <NavBar
          title={!edit.id ? "Nuevo producto" : "Editar producto"}
          navButtonComponent={
            <i
              className="fas fa-arrow-left nav-button-back"
              onClick={() => onClose()}
            />
          }
        />
      </div>
      <div className="page--content">
        <div className="card">
          <div className="form">
            <label htmlFor="descripcion">Descripción:</label>
            <input
              type="text"
              value={edit.descripcion}
              onChange={(e) => {
                edit.descripcion = e.currentTarget.value;
                setEdit({ ...edit });
              }}
            />

            <label htmlFor="categoria">Categoría:</label>
            <Select
              value={edit.categoriaProductoId}
              items={categorias}
              itemKey={(item) => item.id}
              itemDisplay={(item) => item.nombre}
              onChange={(e) => handleChangeCategoria(e.currentTarget.value)}
            />

            <label>Proveedor:</label>
            <AutoComplete
              items={proveedores}
              value={producto.proveedor}
              itemKey={(item) => item.id}
              itemDisplay={(item) => item.nombre}
              onItemSelected={(e) => handleChangeProveedor(e)}
            />

            <label htmlFor="unidad">Unidad:</label>
            <Select
              items={unidadesMedida}
              itemKey={(item) => item}
              itemDisplay={(item) => item}
              value={edit.unidad}
              onChange={(e) => {
                edit.unidad = e.currentTarget.value;
                setEdit({ ...edit });
              }}
            />

            <div className="grid gap-small intervalo">
              <div>
                <label>Intervalo:</label>
                <input
                  type="number"
                  value={edit.intervalo}
                  onChange={(e) => {
                    edit.intervalo = e.currentTarget.value;
                    setEdit({ ...edit });
                  }}
                />
              </div>
              <div>
                {edit.unidad && edit.unidad.toLowerCase() === "pieza" && (
                  <div>
                    <label>Peso por pieza:</label>
                    <input
                      type="number"
                      value={edit.pesoPorPieza}
                      onChange={(e) => {
                        edit.pesoPorPieza = e.currentTarget.value;
                        setEdit({ ...edit });
                      }}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="div-between">
              <Checkbox
                checked={edit.activo}
                label="Activo"
                onCheckedChanged={() => {
                  edit.activo = !edit.activo;
                  setEdit({ ...edit });
                }}
              />

              <Checkbox
                checked={edit.agotado}
                label="Agotado"
                onCheckedChanged={() => {
                  edit.agotado = !edit.agotado;
                  setEdit({ ...edit });
                }}
              />
            </div>
          </div>

          <hr />

          <div className="edit-product--image-container">
            <div>
              <img id="productImage" src={producto.imagenUrl} alt="" />
            </div>
            <input
              type="file"
              name="pickFile"
              id="pickFile"
              accept="image/png, image/jpeg, image/webp"
              onChange={handleUploadImage}
            />
            <button
              className="btn primary"
              onClick={() => document.getElementById("pickFile").click()}
            >
              Seleccionar imagen
            </button>
          </div>
        </div>

        <div className="card edit-product--precios">
          <div className="form">
            <label>Costo:</label>
            <input
              type="number"
              value={edit.costoUnitario}
              onChange={(e) => {
                edit.costoUnitario = Number(e.currentTarget.value);
                edit.precio = CalcularPrecioVenta(edit);
                setEdit({ ...edit });
              }}
            />

            <label>Margen:</label>
            <input
              type="number"
              value={edit.margen}
              onChange={(e) => {
                edit.margen = Number(e.currentTarget.value);
                edit.precio = CalcularPrecioVenta(edit);
                setEdit({ ...edit });
              }}
            />

            <div className="intervalo">
              <input
                type="text"
                placeholder="Concepto"
                value={edit.conceptoSobrePrecio1}
                onChange={(e) => {
                  edit.conceptoSobrePrecio1 = e.currentTarget.value;
                  setEdit({ ...edit });
                }}
              />
              <input
                type="number"
                placeholder="Monto"
                value={edit.montoSobrePrecio1}
                onChange={(e) => {
                  edit.montoSobrePrecio1 = Number(e.currentTarget.value);
                  edit.precio = CalcularPrecioVenta(edit);
                  setEdit({ ...edit });
                }}
              />
              <input
                type="text"
                placeholder="Concepto"
                value={edit.conceptoSobrePrecio2}
                onChange={(e) => {
                  edit.conceptoSobrePrecio2 = e.currentTarget.value;
                  setEdit({ ...edit });
                }}
              />
              <input
                type="number"
                placeholder="Monto"
                value={edit.montoSobrePrecio2}
                onChange={(e) => {
                  edit.montoSobrePrecio2 = Number(e.currentTarget.value);
                  edit.precio = CalcularPrecioVenta(edit);
                  setEdit({ ...edit });
                }}
              />
            </div>

            <div className="products--precio">
              <label>Precio:</label>
              <label>$ {edit.precio}</label>
            </div>
          </div>
        </div>

        <div className="page-footer">
          <button className="btn secondary" onClick={handleSave}>
            Guardar
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditProduct;
