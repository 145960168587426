import { useEffect, useState } from "react";
import { GetCuentasContables } from "../../../services/cuentasContablesServices";
import ButtonAdd from "../../utils/buttonAdd";
import NavBar from "../../utils/nav/NavBar";
import NavMenu from "../../utils/nav/NavMenu";
import Authorization from "../../utils/secure/Authorization";
import UpdateCuenta from "./updateCuenta";

const CuentasPage = () => {
  const [permissionChecked, setPermissionChecked] = useState(false);
  const [permissionGranted, setPermissionGranted] = useState(false);

  const [adding, setAdding] = useState(null);
  const [editingId, setEditingId] = useState(null);

  const [cuentas, setCuentas] = useState([]);
  useEffect(() => {
    cargarDatos();
  }, []);

  const cargarDatos = async () => {
    try {
      let cuentas = await GetCuentasContables();

      setCuentas(cuentas);
    } catch (error) {
      alert(error);
    }
  };

  function getNombreGrupo(grupo) {
    switch (grupo) {
      case 1:
        return "Activo /";
      case 2:
        return "Pasivo /";
      case 3:
        return "Capital /";
      case 4:
        return "Ingresos /";
      case 5:
        return "Gastos /";
      default:
        return "Desconocido /";
    }
  }

  if (!permissionChecked)
    return (
      <div id="gastos">
        <Authorization
          permission="Cuentas"
          onPermissionGranted={() => {
            setPermissionChecked(true);
            setPermissionGranted(true);
          }}
        />
      </div>
    );

  if (permissionChecked && !permissionGranted) return <div></div>;

  if (adding)
    return (
      <UpdateCuenta
        cuentaId={0}
        onClose={() => setAdding(false)}
        onSave={() => {
          setAdding(false);
          cargarDatos();
        }}
      />
    );

  if (editingId)
    return (
      <UpdateCuenta
        cuentaId={editingId}
        onClose={() => setEditingId(null)}
        onSave={() => {
          setEditingId(null);
          cargarDatos();
        }}
      />
    );
  return (
    <div id="cuentas">
      <div className="page-header">
        <NavBar
          title="Cuentas"
          navButtonComponent={<NavMenu />}
          toolbar={<ButtonAdd onClick={() => setAdding(true)} />}
        />
      </div>

      <div className="padding-1 page-list">
        {cuentas.map((cuenta, i) => (
          <div
            key={i}
            className="margin-bottom-1 border-bottom grid font-smaller"
            onClick={() => setEditingId(cuenta.id)}
          >
            <div className="col1">
              <div>
                {getNombreGrupo(cuenta.grupo)} {cuenta.nombre}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CuentasPage;
