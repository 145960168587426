import React, { useState } from "react";
import { round } from "../../services/mathServices";

const QuantityEntry = ({
  minValue = 0,
  maxValue = -1,
  step = 1,
  value,
  onValueChanged,
  onValueChanging,
  decimals = 2,
  disabled = false,
}) => {
  const decrementValue = () => {
    if (disabled) return;
    if (value - step < minValue) return;

    let newValue = round(value - step, decimals);
    if (onValueChanging) {
      let e = { cancel: false };
      onValueChanging(newValue, e);
      if (e.cancel) return;
    }

    value = newValue;
    onValueChanged(value);
  };
  const incrementValue = () => {
    if (disabled) return;
    if (maxValue > -1 && value + step > maxValue) return;

    let newValue = round(value + step, decimals);
    if (onValueChanging) {
      let e = { cancel: false };
      onValueChanging(newValue, e);
      if (e.cancel) return;
    }

    value = newValue;
    onValueChanged(value);
  };
  const changeValue = (e) => {
    if (disabled) return;

    let newValue = e.currentTarget.value;
    if (onValueChanging) {
      let e = { cancel: false };
      onValueChanging(newValue, e);
      if (e.cancel) return;
    }

    if (!newValue) newValue = 0;
    onValueChanged(round(newValue, decimals));
  };

  return (
    <div className="qty-entry">
      <div
        className={
          "qty-button warning " +
          (value <= minValue ? " disabled" : " ") +
          (disabled ? " disabled " : " ")
        }
        onClick={decrementValue}
      >
        <i className="fas fa-minus"></i>
      </div>
      <div>
        <input
          id="quantity"
          type="number"
          pattern="[0-9]*"
          inputMode="decimal"
          value={value}
          onChange={changeValue}
          disabled={disabled}
        />
      </div>
      <div
        className={
          "qty-button success " +
          (maxValue > -1 && value + step > maxValue ? " disabled" : " ") +
          (disabled ? " disabled " : " ")
        }
        onClick={incrementValue}
      >
        <i className="fas fa-plus"></i>
      </div>
    </div>
  );
};

export default QuantityEntry;
