import { useEffect, useState } from "react";
import { GetCuentasContablesGastos } from "../../../services/cuentasContablesServices";
import {
  AddConceptoGasto,
  EditConceptoGasto,
  GetConceptosGastos,
} from "../../../services/gastosServices";
import Checkbox from "../../utils/checkBox";
import NavBar from "../../utils/nav/NavBar";
import Select from "../../utils/select";

const UpdateConceptoGasto = ({ conceptoId, onClose, onSave }) => {
  const [cuentas, setCuentas] = useState([]);

  const [nombre, setNombre] = useState("");
  const [deducible, setDeducible] = useState(false);
  const [cuentaContableGastoId, setCuentaContableGastoId] = useState(-1);

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    async function Init() {
      try {
        let cuentas = await GetCuentasContablesGastos();
        setCuentas(cuentas);
        if (cuentas.length > 0) setCuentaContableGastoId(cuentas[0].id);

        if (conceptoId > 0) {
          let conceptos = await GetConceptosGastos();
          let concepto = conceptos.filter((x) => x.id === conceptoId);
          if (concepto.length === 0) {
            setErrorMessage("No existe el concepto");
            return;
          }

          setNombre(concepto[0].nombre);
          setDeducible(concepto[0].deducible);
          setCuentaContableGastoId(concepto[0].cuentaContableGastoId);
        }
      } catch (error) {
        if (error.response) {
          alert(error.response.data);
        } else {
          alert(error.message);
        }
      }
    }
    Init();
  }, []);

  const handleSave = async () => {
    if (!validar()) return;

    try {
      let concepto = {
        id: conceptoId,
        deducible,
        nombre,
        cuentaContableGastoId,
      };

      let response = null;
      if (conceptoId === 0) response = await AddConceptoGasto(concepto);
      else response = await EditConceptoGasto(concepto);
      if (!response.exitoso) {
        setErrorMessage(response.mensajeError);
        return;
      }

      onSave();
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const validar = () => {
    if (!nombre) {
      setErrorMessage("Escriba el nombre del concepto");
      return false;
    }

    if (cuentaContableGastoId <= 0) {
      setErrorMessage("Seleccione la cuenta del gasto");
      return false;
    }

    setErrorMessage("");
    return true;
  };

  return (
    <div>
      <div className="overlay">
        <div className="modal">
          <div id="updateConcepto-gasto" className="modal--content">
            <div className="modal-header">
              <NavBar
                title={conceptoId === 0 ? "Nuevo concepto" : "Editar concepto"}
                navButtonComponent={
                  <i
                    className="fas fa-times nav-button-back"
                    onClick={onClose}
                  />
                }
              />
            </div>
            <div className="modal-body">
              <div className="card">
                <div className="form form--vertical">
                  <label>Nombre</label>
                  <input
                    type="text"
                    value={nombre}
                    onChange={(e) => setNombre(e.currentTarget.value)}
                  />

                  <Checkbox
                    checked={deducible}
                    label="Deducible"
                    onCheckedChanged={() => setDeducible(!deducible)}
                  />

                  <label>Cuenta gasto</label>
                  <Select
                    value={cuentaContableGastoId}
                    onChange={(e) => {
                      setCuentaContableGastoId(e.currentTarget.value);
                    }}
                    items={cuentas}
                    itemKey={(cuenta) => cuenta.id}
                    itemDisplay={(cuenta) => cuenta.nombre}
                  />
                </div>

                <div className="div-right">
                  <button className="btn primary" onClick={() => handleSave()}>
                    Guardar
                  </button>
                </div>
                <div className="form--error font-small">{errorMessage}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateConceptoGasto;
