import webAPIServices from "./webAPIServices";

export async function GetCxC() {
  const appsvc = new webAPIServices();

  let requestUrl = `/backoffice/cxc`;

  return appsvc.Get(requestUrl);
}

export async function GetFormasPago() {
  const appsvc = new webAPIServices();

  let requestUrl = `/backoffice/FormaPago`;

  return appsvc.Get(requestUrl);
}

export async function RegistrarPago(pago) {
  const appsvc = new webAPIServices();

  let requestUrl = `/backoffice/cxc/pago`;

  return appsvc.Post(requestUrl, pago);
}
