import React, { useState, useRef } from "react";

const Search = ({ initialSearchText = "", onSearch }) => {
  const [searchText, setSearchText] = useState(initialSearchText);

  const useFocus = () => {
    const htmlElRef = useRef(null);
    const setFocus = () => {
      htmlElRef.current && htmlElRef.current.focus();
    };

    return [htmlElRef, setFocus];
  };
  const [inputRef, setInputFocus] = useFocus();

  const [searchTimeout, setSearchTimeout] = useState();

  function updateSearchText(text) {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
      setSearchTimeout();
    }
    if (!text) {
      clearSearchText();
      return;
    }

    setSearchText(text);
    setSearchTimeout(
      setTimeout(() => {
        onSearch(text);
      }, 1000)
    );
  }

  function clearSearchText() {
    clearTimeout();
    setSearchTimeout();
    setSearchText("");
    onSearch("");
    setInputFocus();
  }

  return (
    <div className="searchBox">
      <i className="fas fa-search"></i>
      <input
        ref={inputRef}
        type="search"
        placeholder="buscar"
        value={searchText}
        onChange={(e) => updateSearchText(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            if (searchTimeout) clearTimeout();
            onSearch(searchText);
          }
        }}
      />
      {searchText && (
        <i
          className="fas fa-times search--clear-button"
          onClick={(e) => clearSearchText()}
        ></i>
      )}
    </div>
  );
};

export default Search;
