export function fixedLengthStr(str, len, justify = "l", padChar = " ") {
  if (str.length < len) {
    let dif = len - str.length;
    let r = str;
    if (justify === "r") {
      r = "";
      for (var i = 0; i < dif; i++) r += padChar;
      r += str;
    } else {
      for (var i = 0; i < dif; i++) r += padChar;
    }
    return r;
  }

  if (str.length > len) {
    return str.slice(0, len);
  }

  return str;
}

export function formatNumber(number, decimals = 2) {
  return number.toLocaleString("en-US", {
    maximumFractionDigits: decimals,
    minimumFractionDigits: decimals,
  });
}
