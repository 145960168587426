import { GetSettings } from "../../../services/settingsServices";
import { fixedLengthStr, formatNumber } from "../../../services/stringServices";
export class printOrderFulfill {
  constructor(pedido) {
    this.pedido = pedido;
    this.ePosDev = new window.epson.ePOSDevice();
    this.printer = null;
    this.leyenda = "";

    console.log("printOrderfulfill", pedido);
  }

  async #readSettings() {
    this.settings = await GetSettings();
    return this.settings;
  }

  #connect(printDelegate) {
    let ip = null;
    let port = null;
    GetSettings().then((data) => {
      ip = data.ipImpresoraTicketPedido;
      port = data.puertoImpresoraTicketPedido;
      this.leyenda = data.leyenda;
      console.log("connecting...", ip);

      this.ePosDev.connect(ip, port, (data) => {
        console.log("connection response", data);
        if (data === "OK" || data === "SSL_CONNECT_OK") {
          this.ePosDev.createDevice(
            "local_printer",
            this.ePosDev.DEVICE_TYPE_PRINTER,
            { crypto: true, buffer: false },
            (devobj, retcode) => {
              if (retcode === "OK") {
                this.printer = devobj;
                printDelegate();
              } else {
                throw retcode;
              }
            }
          );
        } else {
          throw data;
        }
      });
    });
  }

  PrintTicket(afterPrintingDelegate) {
    this.#readSettings().then((setting) => {
      if (!this.printer)
        this.#connect(() =>
          this.#prnTicket(this.pedido, afterPrintingDelegate)
        );
      else this.#prnTicket(this.pedido, afterPrintingDelegate);
    });
  }

  #prnTicket(pedido, afterPrintingDelegate) {
    this.printer.addTextDouble(true, true);
    this.printer.addTextAlign(this.printer.ALIGN_CENTER);
    this.printer.addLogo(126, 42);
    this.printer.addFeedLine(2);
    this.printer.addTextAlign(this.printer.ALIGN_LEFT);
    this.printer.addText("PEDIDO ");
    this.printer.addTextStyle(false, false, true, this.printer.COLOR_1);
    this.printer.addText(
      `${pedido.id} ${pedido.fechaEntrega}\nRUTA:${pedido.ruta}\n`
    );

    this.printer.addText(`\n${pedido.nombre.toUpperCase()}\n\n`);
    this.printer.addTextStyle(false, false, false, this.printer.COLOR_1);
    this.printer.addTextSize(1, 1);
    this.printer.addText(`${pedido.domicilio.toUpperCase()}\n`);
    if (pedido.referencias)
      this.printer.addText(`${pedido.referencias.toUpperCase()}\n`);
    if (pedido.telefono)
      this.printer.addText(`${pedido.telefono.toUpperCase()}\n`);
    if (pedido.notas) this.printer.addText(`${pedido.notas.toUpperCase()}\n`);
    this.printer.addFeedLine(2);
    let suma = 0;
    let countSurtidas = 0;

    let sortedPartidas = pedido.partidas.sort(function (a, b) {
      return (
        a.categoria.localeCompare(b.categoria) ||
        a.descripcion.localeCompare(b.descripcion)
      );
    });

    let lastCategoria = null;
    sortedPartidas.forEach((p) => {
      if (p.cantidadSurtida > 0) countSurtidas += 1;

      if (lastCategoria !== p.categoria) {
        this.printer.addTextSize(2, 1);
        this.printer.addText(`${p.categoria}\n`);
        this.printer.addTextSize(1, 1);
        lastCategoria = p.categoria;
      }

      this.printer.addText(
        `${fixedLengthStr(
          formatNumber(p.cantidadSurtida),
          6,
          "r"
        )} ${fixedLengthStr(p.unidad.toUpperCase(), 5)} ${fixedLengthStr(
          p.descripcion.toUpperCase(),
          21
        )} ${fixedLengthStr(formatNumber(p.importeSurtido), 7, "r")}\n`
      );
      suma += p.importeSurtido;
    });
    this.printer.addFeed();
    this.printer.addText(
      `${fixedLengthStr(" ", 27)}${fixedLengthStr("", 15, "l", "-")}`
    );
    this.printer.addText(fixedLengthStr(" ", 27));
    this.printer.addText("TOTAL:");
    this.printer.addTextStyle(false, false, true, this.printer.COLOR_1);
    this.printer.addText(`${fixedLengthStr(formatNumber(suma), 9, "r")}\n`);
    this.printer.addTextStyle(false, false, false, this.printer.COLOR_1);
    this.printer.addText(`${countSurtidas} PARTIDAS SURTIDAS\n`);
    this.printer.addFeed();
    this.printer.addTextAlign(this.printer.ALIGN_CENTER);
    this.printer.addTextStyle(false, false, true, this.printer.COLOR_1);
    this.printer.addText("www.todoamipuerta.com");
    this.printer.addTextStyle(false, false, false, this.printer.COLOR_1);
    this.printer.addFeedLine(2);
    if (this.settings.notasTicketPedido)
      this.printer.addText(this.settings.notasTicketPedido);

    this.printer.addFeedLine(5);
    this.printer.addCut(this.printer.CUT_FEED);

    this.printer.send();

    if (afterPrintingDelegate) afterPrintingDelegate();
  }

  PrintLabel(copias) {
    this.#readSettings().then((setting) => {
      if (!this.printer)
        this.#connect(() => this.#prnCaja(this.pedido, copias));
      else this.#prnCaja(this.pedido, copias);
    });
  }

  #prnCaja(pedido, cajas) {
    for (var i = 0; i < cajas; i++) {
      this.printer.addFeedLine(5);
      this.printer.addTextDouble(true, true);
      this.printer.addText(`RUTA: ${pedido.ruta.toUpperCase()}\n`);
      this.printer.addTextDouble(false, false);
      this.printer.addText(`FECHA: ${pedido.fechaEntrega}\n`);
      this.printer.addText("PEDIDO:");
      this.printer.addTextStyle(false, false, true, this.printer.COLOR_1);
      this.printer.addText(`${pedido.id}\n`);
      this.printer.addTextDouble(true, true);
      this.printer.addTextStyle(false, false, false, this.printer.COLOR_1);
      this.printer.addText(`${pedido.nombre.toUpperCase()}\n`);
      this.printer.addFeed();
      this.printer.addTextDouble(true, true);
      this.printer.addTextAlign(this.printer.ALIGN_CENTER);
      this.printer.addFeedLine(5);
      this.printer.addCut(this.printer.CUT_FEED);
    }

    this.printer.send();
  }
}
