import webAPIServices from "./webAPIServices";
import { round } from "./mathServices";
import { isNumber } from "lodash";

export async function GetProductos() {
  const appsvc = new webAPIServices();

  let requestUrl = `/backoffice/productos`;

  return appsvc.Get(requestUrl);
}

export async function PutProducto(producto) {
  const appsvc = new webAPIServices();

  let requestUrl = `/backoffice/productos/${producto.id}`;

  return appsvc.Put(requestUrl, producto);
}

export async function PostProducto(producto) {
  const appsvc = new webAPIServices();

  let requestUrl = `/backoffice/productos`;

  return appsvc.Post(requestUrl, producto);
}

export async function UploadImagenProducto(productoId, imageToUpload) {
  const formData = new FormData();
  formData.append("file", imageToUpload);
  const appsvc = new webAPIServices();
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  return await appsvc.Post(
    `/backoffice/Productos/${productoId}/Imagen`,
    formData,
    config
  );
}

export async function GetUnidadesMedida() {
  const appsvc = new webAPIServices();

  let requestUrl = `/productos/unidadesMedida`;

  return appsvc.Get(requestUrl);
}

export function CalcularPrecioVenta(producto) {
  let precio = producto.costoUnitario;
  if (producto.margen > 0) {
    precio = round(producto.costoUnitario / producto.margen, 2);
  }

  if (isNumber(producto.montoSobrePrecio1))
    precio += producto.montoSobrePrecio1;
  if (isNumber(producto.montoSobrePrecio2))
    precio += producto.montoSobrePrecio2;

  // Redondea a 50c
  precio = Math.ceil(precio / 0.5) * 0.5;

  return precio;
}

export function GetExistencias() {
  const appsvc = new webAPIServices();

  let requestUrl = `/backoffice/inventarios/existencias`;

  return appsvc.Get(requestUrl);
}
