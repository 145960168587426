import webAPIServices from "./webAPIServices";
import moment from "moment";

export async function GetGastos(rangoFechas) {
  const appsvc = new webAPIServices();

  let desde = moment(rangoFechas.Desde).format("YYYY-MM-DD");
  let hasta = moment(rangoFechas.Hasta).format("YYYY-MM-DD");

  let requestUrl = `/backoffice/gastos?desde=${desde}&hasta=${hasta}`;

  return appsvc.Get(requestUrl);
}

export async function GetConceptosGastos() {
  const appsvc = new webAPIServices();
  let requestUrl = `/backoffice/gastos/conceptos`;

  return appsvc.Get(requestUrl);
}

export async function AddGasto(gasto) {
  const appsvc = new webAPIServices();
  let requestUrl = `/backoffice/gastos`;

  return appsvc.Post(requestUrl, gasto);
}

export async function EditGasto(gasto) {
  const appsvc = new webAPIServices();
  let requestUrl = `/backoffice/gastos`;

  return appsvc.Put(requestUrl, gasto);
}

export async function DeleteGasto(gastoId) {
  const appsvc = new webAPIServices();
  let requestUrl = `/backoffice/gastos/${gastoId}`;

  return appsvc.Delete(requestUrl);
}

export async function GetGasto(gastoId) {
  const appsvc = new webAPIServices();
  let requestUrl = `/backoffice/gastos/${gastoId}`;

  return appsvc.Get(requestUrl);
}

export async function AddConceptoGasto(concepto) {
  const appsvc = new webAPIServices();
  let requestUrl = `/backoffice/gastos/conceptos`;

  return appsvc.Post(requestUrl, concepto);
}

export async function EditConceptoGasto(concepto) {
  const appsvc = new webAPIServices();
  let requestUrl = `/backoffice/gastos/conceptos`;

  return appsvc.Put(requestUrl, concepto);
}
