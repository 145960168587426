import React, { useState, useEffect } from "react";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
import * as locales from "react-date-range/dist/locale";

import { RangoFechas } from "../../helpers/datesHelper";

const DateRangeLink = ({ prefix = "", rangoFechas, onChange }) => {
  const [state, setState] = useState([
    {
      startDate: rangoFechas.Desde,
      endDate: rangoFechas.Hasta,
      key: "selection",
    },
  ]);
  const [showCalendar, setShowCalendar] = useState(false);

  useEffect(() => {
    if (
      rangoFechas.Desde != state.startDate ||
      rangoFechas.Hasta != state.endDate
    ) {
      setState([
        {
          startDate: rangoFechas.Desde,
          endDate: rangoFechas.Hasta,
          key: "selection",
        },
      ]);
    }
  }, [rangoFechas]);

  return (
    <div id="dateRangeLink">
      <button
        className="btn secondary link"
        onClick={() => setShowCalendar(!showCalendar)}
      >
        {prefix}
        {rangoFechas.toString()}
      </button>
      {showCalendar && (
        <div id="dateRangeLink--calendar">
          <DateRange
            locale={locales.es}
            editableDateInputs={true}
            onChange={(item) => setState([item.selection])}
            moveRangeOnFirstSelection={false}
            ranges={state}
          />
          <div className="footer">
            <button
              className="btn primary"
              onClick={() => {
                let nuevoRango = new RangoFechas(
                  state[0].startDate,
                  state[0].endDate
                );
                onChange(nuevoRango);
                setShowCalendar(false);
              }}
            >
              Aplicar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DateRangeLink;
