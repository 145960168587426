import webAPIServices from "./webAPIServices";

export async function GetDiasInhabiles() {
  const appsvc = new webAPIServices();

  let requestUrl = `/backoffice/inhabiles`;

  return appsvc.Get(requestUrl);
}

export async function PostDiaInhabil(inhabil) {
  const appsvc = new webAPIServices();

  let requestUrl = `/backoffice/inhabiles`;

  return appsvc.Post(requestUrl, inhabil);
}

export async function PutDiaInhabil(inhabil) {
  const appsvc = new webAPIServices();

  let requestUrl = `/backoffice/inhabiles`;

  return appsvc.Put(requestUrl, inhabil);
}
