// Regresa la fecha de entrega mas proxima, segun la fecha actual
export function FechaEntregaDefault() {
  // Por omisión sugiere la fecha de entrega de mañana
  let fechaEntrega = new Date();
  fechaEntrega.setDate(fechaEntrega.getDate() + 1);

  // Si la fecha de entrega es domingo, pasa al lunes
  if (fechaEntrega.getDay() === 0)
    fechaEntrega.setDate(fechaEntrega.getDate() + 1);

  return fechaEntrega;
}

export function GetPrimerDiaDelMes() {
  let hoy = new Date();
  return new Date(hoy.getFullYear(), hoy.getMonth(), 1);
}

export function GetFechaDesde(fecha) {
  return new Date(fecha.getFullYear(), fecha.getMonth(), fecha.getDate());
}

export function GetFechaHasta(fecha) {
  return new Date(
    fecha.getFullYear(),
    fecha.getMonth(),
    fecha.getDate(),
    23,
    59,
    59
  );
}

export class RangoFechas {
  constructor(desde = null, hasta = null) {
    if (!desde || !hasta) {
      desde = GetFechaDesde(new Date());
      hasta = GetFechaHasta(new Date());
    }

    this.Desde = desde;
    this.Hasta = hasta;
  }

  toString() {
    let desde = GetFechaDesde(this.Desde);
    let hasta = GetFechaDesde(this.Hasta);
    if (desde - hasta === 0) {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      return `${desde.toLocaleDateString("es-MX", options)}`;
    }

    if (
      desde.getDate() === 1 &&
      desde.getMonth() === 0 &&
      hasta.getDate() === 31 &&
      hasta.getMonth() === 11 &&
      desde.getFullYear() === hasta.getFullYear()
    ) {
      return `del año ${desde.getFullYear()}`;
    }
    // Mismo mes y mismo axo
    if (
      desde.getMonth() === hasta.getMonth() &&
      desde.getFullYear() === hasta.getFullYear()
    ) {
      // Primer y ultimo dia del mes
      if (
        desde.getDate() === 1 &&
        hasta.getDate() === daysInMonth(hasta.getMonth(), hasta.getFullYear())
      ) {
        return desde.toLocaleDateString("es-MX", {
          month: "long",
          year: "numeric",
        });
      }
      // Otros dias que no cubren todo el mes
      return `${desde.getDate()} al ${hasta.getDate()} de ${desde.toLocaleDateString(
        "es-MX",
        { month: "long", year: "numeric" }
      )}`;
    } else {
      // Mismo año, distinto mes
      if (desde.getFullYear() === hasta.getFullYear()) {
        return `${desde.toLocaleDateString("es-MX", {
          day: "numeric",
          month: "long",
        })} al ${hasta.toLocaleDateString("es-MX", {
          day: "numeric",
          month: "long",
          year: "numeric",
        })}`;
      }
    }

    // Distinto mes y año
    return `${desde.toLocaleDateString("es-MX", {
      day: "numeric",
      month: "long",
      year: "numeric",
    })} al ${hasta.toLocaleDateString("es-MX", {
      day: "numeric",
      month: "long",
      year: "numeric",
    })}`;
  }
}

export function daysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}

export function formatShortDate(fecha) {
  let date = new Date(fecha);
  return date.toLocaleDateString("es-MX", {
    day: "numeric",
    month: "2-digit",
    year: "2-digit",
  });
}
