import React, { useState, useEffect } from "react";
import Authorization from "../../utils/secure/Authorization";
import NavBar from "../../utils/nav/NavBar";
import NavMenu from "../../utils/nav/NavMenu";
import { saveAs } from "file-saver";
import Loading from "../../utils/loading";

import "../../../css/salesDashboard.css";
import {
  FechaEntregaDefault,
  GetFechaDesde,
  GetFechaHasta,
  RangoFechas,
} from "../../../helpers/datesHelper";
import DateRangeLink from "../../utils/dateRangeLink";
import {
  GetSalesDashboard,
  DownloadAcumuladoVentasProducto,
} from "../../../services/dashboardServices";
import NumberFormat from "react-number-format";
import OrdersDashboard from "./ordersDashboard";

import PullToRefresh from "react-simple-pull-to-refresh";

const SalesDashboard = () => {
  const [permissionChecked, setPermissionChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rangoFechas, setRangoFechas] = useState({});
  const [dashboard, setDashboard] = useState({});
  const [currentDrilldown, setCurrentDrilldown] = useState(null);

  const DRILLDOWN_Pedidos = "pedidos";

  useEffect(() => {
    if (!rangoFechas.Desde) {
      let rango = new RangoFechas(
        GetFechaDesde(FechaEntregaDefault()),
        GetFechaHasta(FechaEntregaDefault())
      );

      setRangoFechas(rango);
      cargarDatos(rango);
    }
  }, []);

  const cargarDatos = async (rango) => {
    setLoading(true);

    try {
      let dashboard = await GetSalesDashboard(rango);
      setDashboard(dashboard);
      setRangoFechas(rango);
    } finally {
      setLoading(false);
    }
  };

  const descargarAcumuladoPorProducto = async () => {
    setLoading(true);

    try {
      let blob = await DownloadAcumuladoVentasProducto(rangoFechas);
      console.log("blob", blob);
      saveAs(blob, `Acumulado de ventas por producto.xlsx`);
    } finally {
      setLoading(false);
    }
  };

  if (currentDrilldown) {
    // eslint-disable-next-line default-case
    switch (currentDrilldown) {
      case DRILLDOWN_Pedidos: {
        return (
          <OrdersDashboard
            rangoFechas={rangoFechas}
            onClose={() => setCurrentDrilldown(null)}
          />
        );
      }
    }
  }

  return (
    <div id="sales-dashboard">
      {!permissionChecked && (
        <Authorization
          permission="Ventas/Dashboard"
          onPermissionGranted={() => setPermissionChecked(true)}
        />
      )}
      <PullToRefresh onRefresh={() => cargarDatos(rangoFechas)}>
        <div className="page-header">
          <NavBar title="Ventas" navButtonComponent={<NavMenu />} />
          <div id="fechaEntrega-bar" className="bar single">
            <DateRangeLink
              prefix="Entregas del "
              rangoFechas={rangoFechas}
              onChange={(rango) => cargarDatos(rango)}
            />
          </div>
        </div>
        <div
          id="sales-dashboard--totalPedidos"
          className="card"
          onClick={() => setCurrentDrilldown(DRILLDOWN_Pedidos)}
        >
          <div className="grid">
            <div className="col1">
              <div id="noPedidos">
                <NumberFormat
                  value={dashboard.noPedidos}
                  displayType="text"
                  decimalScale="0"
                  thousandSeparator
                />
                <div>pedidos</div>
              </div>
              <div id="noPedidosDiferencia">
                <div
                  className={
                    dashboard.noPedidosDiferenciaPorcentaje > 0
                      ? " positivo "
                      : " negativo "
                  }
                >
                  <NumberFormat
                    value={dashboard.noPedidosDiferenciaPorcentaje}
                    displayType="text"
                    decimalScale="0"
                    thousandSeparator
                  />
                  %
                </div>
                <div>
                  {dashboard.noPedidosDiferenciaPorcentaje > 0 && (
                    <i className="fas fa-arrow-up positivo"></i>
                  )}
                  {dashboard.noPedidosDiferenciaPorcentaje < 0 && (
                    <i className="fas fa-arrow-down negativo"></i>
                  )}
                </div>
              </div>
            </div>
            <div className="col2">
              <div className="label-data border-bottom">
                <div className="positivo">venta</div>
                <NumberFormat
                  value={dashboard.ventaTotal}
                  displayType="text"
                  decimalScale="0"
                  thousandSeparator
                />
              </div>
              <div className="label-data border-bottom">
                <div className="negativo">costo</div>
                <NumberFormat
                  value={dashboard.costoTotal}
                  displayType="text"
                  decimalScale="0"
                  thousandSeparator
                />
              </div>
              <div className="label-data">
                <div className="negativo">costo de envio</div>
                <NumberFormat
                  value={dashboard.costoEnvios}
                  displayType="text"
                  decimalScale="0"
                  thousandSeparator
                />
              </div>
              <div className="label-data">
                <div className="warning-fore">utilidad</div>
                <NumberFormat
                  value={dashboard.utilidadTotal}
                  displayType="text"
                  decimalScale="0"
                  thousandSeparator
                />
              </div>
            </div>
          </div>
        </div>
        <div id="sales-dashboard--clientes-nuevos-recurrentes" className="card">
          <div className="grid">
            <div>
              <div className="font-medium">
                {dashboard.clientesRecurrentes + dashboard.clientesNuevos}
              </div>
              <div className="font-small positivo">clientes</div>
            </div>
            <div>
              <div className="font-medium">{dashboard.clientesNuevos}</div>
              <div className="font-small positivo">nuevos</div>
            </div>
            <div>
              <div className="font-medium">{dashboard.clientesRecurrentes}</div>
              <div className="font-small positivo">recurrentes</div>
            </div>
          </div>
        </div>
        <div id="sales-dashboard--ticket-promedio" className="card">
          <h2>ticket promedio</h2>
          <div className="grid">
            <div className="col1">
              <div className="label-data">
                <div className="positivo">venta</div>
                <NumberFormat
                  value={dashboard.ticketPromedioVenta}
                  displayType="text"
                  decimalScale="0"
                  thousandSeparator
                />
              </div>
            </div>
            <div className="col2 div-right">
              <div className="label-data">
                <div className="warning-fore">utilidad</div>
                <NumberFormat
                  className="align-right"
                  value={dashboard.ticketPromedioUtilidad}
                  displayType="text"
                  decimalScale="0"
                  thousandSeparator
                />
              </div>
            </div>
          </div>
        </div>
        <div id="sales-dashboard--categoria" className="card">
          <h2>venta por categoría</h2>

          <div className="grid">
            <div className="list-header">Ped</div>
            <div className="list-header">Categoria</div>
            <div className="list-header align-right">Venta</div>
            <div className="list-header align-right">Costo</div>
            <div className="list-header align-right">Utilidad</div>
            {dashboard.ventasPorCategoria &&
              dashboard.ventasPorCategoria
                .sort((a, b) => (a.venta > b.venta ? -1 : 1))
                .map((x) => (
                  <React.Fragment key={x.categoriaId}>
                    <div className="list-row">{x.noPedidos}</div>
                    <div className="list-row">{x.categoria}</div>
                    <div className="list-row align-right">
                      <NumberFormat
                        value={x.venta}
                        displayType="text"
                        decimalScale="0"
                        thousandSeparator
                      />
                    </div>
                    <div className="list-row align-right">
                      <NumberFormat
                        value={x.costo}
                        displayType="text"
                        decimalScale="0"
                        thousandSeparator
                      />
                    </div>
                    <div className="list-row align-right">
                      <NumberFormat
                        value={x.utilidad}
                        displayType="text"
                        decimalScale="0"
                        thousandSeparator
                      />
                    </div>
                  </React.Fragment>
                ))}
          </div>

          <button
            className="btn primary"
            onClick={() => descargarAcumuladoPorProducto()}
          >
            Acumulado
          </button>
        </div>
        <div id="sales-dashboard--ubicacion" className="card">
          <h2>Ventas por zona</h2>
        </div>
      </PullToRefresh>
      <Loading show={loading} />{" "}
    </div>
  );
};

export default SalesDashboard;
