import React from "react";
import { useState, useEffect } from "react";
import { round } from "../../../services/mathServices";
import { formatNumber } from "../../../services/stringServices";

var _ = require("lodash");
const PurchaseReceiptSupplier = ({ compras, onClose }) => {
  const [proveedores, setProveedores] = useState([]);
  useEffect(() => {
    const ans = _(compras)
      .groupBy("proveedor")
      .map((platform, id) => ({
        proveedor: id,
        importeSurtido: round(_.sumBy(platform, "importeSurtido"), 2),
      }))
      .value();

    setProveedores(_.sortBy(ans, (p) => p.proveedor));
  }, []);
  return (
    <div id="purchase-receipt-supplier">
      <div id="purchase-receipt-supplier__title">
        <i className="fas fa-times search--clear-button" onClick={onClose}></i>
        <span>Totales por proveedor</span>
      </div>
      {proveedores.map((x) => (
        <div key={x.proveedor} className="grid">
          <div className="col1">{x.proveedor}</div>
          <div className="col2 text-align-right">
            {formatNumber(x.importeSurtido, 2)}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PurchaseReceiptSupplier;
