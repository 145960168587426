import React, { useState, useEffect } from "react";
import NavBar from "../../utils/nav/NavBar";
import Select from "../../utils/select";

import moment from "moment";
import {
  AddGasto,
  GetConceptosGastos,
  GetGasto,
  EditGasto,
  DeleteGasto,
} from "../../../services/gastosServices";

import { GetCuentasContablesPagos } from "../../../services/cuentasContablesServices";
import { ca } from "date-fns/locale";

const UpdateGasto = ({ gastoId, onClose, onSave }) => {
  const [conceptos, setConceptos] = useState([]);
  const [cuentas, setCuentas] = useState([]);

  const [fecha, setFecha] = useState("");
  const [conceptoId, setConceptoId] = useState(-1);
  const [descripcion, setDescripcion] = useState("");
  const [monto, setMonto] = useState("");
  const [cuentacontablePagoId, setCuentaContablePagoId] = useState(-1);

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    async function Init() {
      try {
        let conceptos = await GetConceptosGastos();
        setConceptos(conceptos);
        if (conceptos.length > 0) setConceptoId(conceptos[0].id);

        let cuentas = await GetCuentasContablesPagos();
        console.log("cuentas", cuentas);
        setCuentas(cuentas);
        if (cuentas.length > 0) setCuentaContablePagoId(cuentas[0].id);

        if (gastoId > 0) {
          let response = await GetGasto(gastoId);
          if (!response.exitoso) {
            setErrorMessage(response.mensajeError);
            return;
          }

          setFecha(moment(response.gasto.fecha).format("YYYY-MM-DD"));
          setConceptoId(response.gasto.conceptoId);
          setDescripcion(response.gasto.descripcion);
          setMonto(response.gasto.monto);
          setCuentaContablePagoId(response.gasto.cuentaContablePagoId);
        } else {
          setFecha(moment(new Date()).format("YYYY-MM-DD"));
        }
      } catch (error) {
        if (error.response) {
          alert(error.response.data);
        } else {
          alert(error.message);
        }
      }
    }
    Init();
  }, []);

  const handleSave = async () => {
    if (!validar()) return;

    try {
      let gasto = {
        id: gastoId,
        fecha,
        conceptoId,
        descripcion,
        monto,
        cuentacontablePagoId,
      };

      let response = null;
      if (gastoId === 0) response = await AddGasto(gasto);
      else response = await EditGasto(gasto);

      if (!response.exitoso) {
        setErrorMessage(response.mensajeError);
        return;
      }

      onSave();
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const validar = () => {
    if (conceptoId <= 0) {
      setErrorMessage("Seleccione el concepto del gasto");
      return false;
    }

    if (!descripcion) {
      setErrorMessage("Escriba la descripcion del gasto");
      return false;
    }

    if (monto <= 0) {
      setErrorMessage("Indique el monto del gasto");
      return false;
    }

    if (cuentacontablePagoId < 0) {
      setErrorMessage("Seleccione la cuenta con la que se realiza el pago");
      return false;
    }

    setErrorMessage(null);
    return true;
  };

  const handleDelete = async () => {
    if (gastoId <= 0) return;
    if (!window.confirm("¿ Está seguro que desea eliminar el gasto?")) return;

    try {
      await DeleteGasto(gastoId);
      onSave();
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <div>
      <div className="overlay">
        <div className="modal">
          <div id="updateGasto" className="modal--content">
            <div className="modal-header">
              <NavBar
                title={gastoId === 0 ? "Registrar nuevo gasto" : "Editar gasto"}
                navButtonComponent={
                  <i
                    className="fas fa-times nav-button-back"
                    onClick={onClose}
                  />
                }
              />
            </div>

            <div className="modal-body">
              <div className="card">
                <div className="form form--vertical">
                  <label>Fecha</label>
                  <input
                    type="date"
                    value={fecha}
                    onChange={(e) => setFecha(e.currentTarget.value)}
                  />
                  <label>Concepto</label>
                  <Select
                    value={conceptoId}
                    onChange={(e) => setConceptoId(e.currentTarget.value)}
                    items={conceptos}
                    itemKey={(concepto) => concepto.id}
                    itemDisplay={(concepto) => concepto.nombre}
                  />
                  <label>Descripción</label>
                  <input
                    type="text"
                    value={descripcion}
                    onChange={(e) => setDescripcion(e.currentTarget.value)}
                  />
                  <label htmlFor="">Monto</label>
                  <input
                    type="number"
                    value={monto}
                    onChange={(e) => setMonto(e.currentTarget.value)}
                  />

                  <label htmlFor="">Cuenta de pago</label>
                  <Select
                    value={cuentacontablePagoId}
                    onChange={(e) =>
                      setCuentaContablePagoId(e.currentTarget.value)
                    }
                    items={cuentas}
                    itemKey={(cuenta) => cuenta.id}
                    itemDisplay={(cuenta) => cuenta.nombre}
                  />

                  <div className={gastoId > 0 ? "div-between" : "div-right"}>
                    {gastoId > 0 && (
                      <button
                        className="btn danger"
                        onClick={() => handleDelete()}
                      >
                        Eliminar
                      </button>
                    )}
                    <button
                      className="btn primary"
                      onClick={() => handleSave()}
                    >
                      Guardar
                    </button>
                  </div>
                  <div className="form--error">{errorMessage}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateGasto;
