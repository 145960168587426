import React, { useState, useEffect } from "react";
import Authorization from "../../utils/secure/Authorization";
import NavBar from "../../utils/nav/NavBar";
import NavMenu from "../../utils/nav/NavMenu";
import Loading from "../../utils/loading";
import ButtonAdd from "../../utils/buttonAdd";

import {
  DeshabilitarCodigoPostal,
  GetCodigosPostales,
  HabilitarCodigoPostal,
} from "../../../services/codigosPostalesServices";

//import "./mainpagetemplate.css"; // Import component CSS
import Checkbox from "../../utils/checkBox";
import Search from "../../utils/search";
import ScrollToTopButton from "../../utils/scrollToTopButton";

const ZipCodes = () => {
  const [permissionChecked, setPermissionChecked] = useState();
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [searchText, setSearchText] = useState("");

  // Change the permission to check
  const permission = "Catálogos/Códigos postales";

  useEffect(() => {
    cargar();
  }, []);

  const cargar = async () => {
    try {
      setLoading(true);
      let items = await GetCodigosPostales();
      setItems(items);
      filterItems(items, "");
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
    }
  };

  const handleEnableDisableItem = async (item) => {
    try {
      item.habilitado = !item.habilitado;

      if (item.habilitado) await HabilitarCodigoPostal(item.codigoPostal);
      else await DeshabilitarCodigoPostal(item.codigoPostal);

      setItems([...items]);
    } catch (error) {
      alert(error);
    }
  };

  const handleSearch = (search) => {
    setSearchText(search.toLowerCase());
    filterItems(items, search, 0);
  };

  const filterItems = (allItems, search = searchText) => {
    if (!search) {
      setFilteredItems(allItems);
      return;
    }

    let searchLowerCase = search.toLocaleLowerCase();

    let filtered = allItems.filter(
      (x) =>
        x.codigoPostal.toLowerCase().includes(searchLowerCase) ||
        x.colonias.toLowerCase().includes(searchLowerCase) ||
        x.municipio.toLowerCase().includes(searchLowerCase)
    );
    setFilteredItems(filtered);
  };

  return (
    <div id="zip-codes">
      <ScrollToTopButton />
      {!permissionChecked && (
        <Authorization
          permission={permission}
          onPermissionGranted={() => setPermissionChecked(true)}
        />
      )}
      <div className="page-header">
        <NavBar title="Códigos postales" navButtonComponent={<NavMenu />} />
      </div>
      <div className="page-content">
        <Search
          initialSearchText={searchText}
          onSearch={(searchText) => handleSearch(searchText)}
        />
        <div className="page-list">
          {filteredItems.map((item) => (
            <div key={item.codigoPostal} className="list-item">
              <div className="list-item--heading">
                <Checkbox
                  label={item.codigoPostal}
                  checked={item.habilitado}
                  onCheckedChanged={() => handleEnableDisableItem(item)}
                />
              </div>
              <div className="list-item--content">{item.colonias} </div>
              <div className="list-item--content list-item--content-bold">
                {item.municipio}
              </div>
            </div>
          ))}
        </div>
      </div>
      {loading && <Loading />}
    </div>
  );
};

export default ZipCodes;
