import { useState, useEffect, React } from "react";

import NavBar from "../../utils/nav/NavBar";
import PillOptions from "../../utils/pillOptions";
import Loading from "../../utils/loading";
import { round } from "../../../services/mathServices";
import { printOrderFulfill } from "./printOrderFulfill";
import { GetDiferenciaStyle } from "../../utils/utils";
import FulfillOrderPartidaDialog from "./FulfillOrderPartidaDialog";
import {
  GetOrderToFulfill,
  GetPedidosPorSurtirPorProducto,
  PutOrderItemFulfillment,
} from "../../../services/fulfillmentServices";

const FulfillProducto = ({
  fecha,
  producto,
  onClose,
  onUpdateCantidadSurtida,
}) => {
  const [filtroPartidas, setFiltroPartidas] = useState("Todos");
  const [loading, setLoading] = useState(false);
  const [pedidos, setPedidos] = useState([]);
  const [editingPartida, setEditingPartida] = useState();

  useEffect(() => {
    async function cargarPedidos() {
      try {
        setLoading(true);
        let pedidos = await GetPedidosPorSurtirPorProducto(fecha, producto);
        setPedidos(pedidos);
      } catch (error) {
        alert(error);
      } finally {
        setLoading(false);
      }
    }
    cargarPedidos();
  }, []);

  const filterItems = () => {
    let rValue = [];

    switch (filtroPartidas) {
      case "Todos":
        rValue = pedidos;
        break;
      case "por Surtir":
        rValue = pedidos.filter((p) => p.cantidadSurtida === 0);
        break;
      case "con Diferencia":
        rValue = pedidos.filter(
          (p) =>
            p.cantidadSurtida > 0 &&
            round(Math.abs(p.cantidadPendiente) / p.cantidadSolicitada, 2) > 0.1
        );
        break;
      default:
        return;
    }

    return rValue;
  };

  const updateCantidadSurtida = async (partida) => {
    try {
      // Actualiza en backoffice la nueva cantidad
      await PutOrderItemFulfillment(
        partida.pedidoId,
        partida.id,
        partida.cantidadSurtida,
        partida.kilosSurtidos
      );

      // Actualiza la partida y el acumulado del producto
      producto.surtido = 0.0;
      pedidos.forEach(function (item, i) {
        if (item.pedidoPartidaId === partida.id) {
          pedidos[i].cantidadSurtida = partida.cantidadSurtida;
          pedidos[i].cantidadPendiente =
            partida.cantidadSolicitada - partida.cantidadSurtida;
        }
        producto.surtido += item.cantidadSurtida;
      });

      setPedidos([...pedidos]);
      onUpdateCantidadSurtida(producto, partida);
    } catch (error) {
      alert(error);
    }
  };

  let printer = null;

  const printLabel = async (pedidoId) => {
    let pedido = await GetOrderToFulfill(pedidoId);

    if (!printer) printer = new printOrderFulfill(pedido, 1);
    printer.PrintLabel(1);
  };

  if (loading) return <Loading />;

  return (
    <div id="fulfillProducto">
      <div className="page-header">
        <NavBar
          title={"Surtir " + producto.descripcion}
          navButtonComponent={
            <i
              className="fas fa-arrow-left nav-button-back"
              onClick={() => onClose(producto)}
            />
          }
        />
        <div id="orderFulfillment-toolbar" className="bar single">
          <div id="orderFulfillment-filter">
            <PillOptions
              options={["Todos", "por Surtir", "con Diferencia"]}
              variant="primary"
              selected={filtroPartidas}
              onSelect={(option) => setFiltroPartidas(option)}
            />
          </div>
        </div>
      </div>
      <div className="list-item">
        <div id="orders-fulfillment-by-product--items">
          <div className="col1">
            <img className="thumbnail" src={producto.imagenUrl} />
          </div>
          <div className="col2">
            <div className="list-item--heading">{producto.categoria}</div>
            <div className="list-item--heading">{producto.descripcion}</div>
          </div>
        </div>
        <div id="orders-fulfillment-by-product--cantidades">
          <div className="pill selected">
            <div>Solicitado</div>
            <div>
              {producto.solicitado} {producto.unidad}
            </div>
          </div>
          <div className="pill primary">
            <div>Surtido</div>
            <div>
              {Math.round(producto.surtido, 2)} {producto.unidad}
            </div>
          </div>
          <div
            className={
              "pill  " +
              GetDiferenciaStyle(producto.solicitado, producto.surtido)
            }
          >
            <div>Pendiente</div>
            <div>
              {producto.surtido < producto.solicitado && (
                <span>
                  {Math.abs(round(producto.solicitado - producto.surtido, 2))}{" "}
                  {producto.unidad}
                </span>
              )}
              {producto.surtido >= producto.solicitado && <span>0</span>}
            </div>
          </div>
        </div>
      </div>

      <div className="page-list">
        {filterItems().map((p) => (
          <div
            key={p.pedidoPartidaId}
            className="list-item"
            onClick={() => setEditingPartida(p)}
          >
            <div className="list-item--heading">{p.cliente}</div>
            <div className="list-item--content div-between">
              <div>
                <span>{p.pedidoId} Ruta </span>
                <span className="list-item--content-bold">{p.ruta}</span>
              </div>
              <button
                className="btn icon primary"
                onClick={() => printLabel(p.pedidoId)}
              >
                <i className="fas fa-tag"></i>
              </button>
            </div>

            <div id="order-fulfillment--item-grid" className="grid">
              <div className="col1">Solicitado:</div>
              <div className="col2">
                <div className="pill large selected div-center">
                  <div>
                    <span className="cantidad">{p.cantidadSolicitada} </span>
                    <span className="unidad">{p.unidad}</span>
                  </div>
                </div>
              </div>
              <div className="col1">Surtido:</div>
              <div className="col2">
                <div
                  className={
                    "pill large div-center " +
                    GetDiferenciaStyle(p.cantidadSolicitada, p.cantidadSurtida)
                  }
                >
                  <div>
                    <span className="cantidad">{p.cantidadSurtida} </span>
                    <span className="unidad">{p.unidad}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        {editingPartida && (
          <FulfillOrderPartidaDialog
            disabled={editingPartida.estatus !== 1}
            partida={{
              imageUrl: producto.imagenUrl,
              categoria: producto.categoria,
              descripcion: producto.descripcion,
              idProducto: producto.id,
              unidad: editingPartida.unidad,
              intervaloCantidad: editingPartida.intervaloCantidad,
              pesoPorPieza: editingPartida.pesoPorPieza,
              notas: editingPartida.notas,
              cantidadSolicitada: editingPartida.cantidadSolicitada,
              cantidadSurtida: editingPartida.cantidadSurtida,
              kilosSurtidos: editingPartida.kilosSurtidos,
              pedidoId: editingPartida.pedidoId,
              id: editingPartida.pedidoPartidaId,
              cliente: editingPartida.cliente,
            }}
            onClose={() => setEditingPartida(null)}
            onUpdateCantidadSurtida={(e) => updateCantidadSurtida(e)}
          />
        )}
      </div>
    </div>
  );
};

export default FulfillProducto;
