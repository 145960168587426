import { useEffect, useState } from "react";
import {
  AddCuenta,
  EditCuenta,
  GetCuentasContables,
} from "../../../services/cuentasContablesServices";
import NavBar from "../../utils/nav/NavBar";
import Select from "../../utils/select";

const UpdateCuenta = ({ cuentaId, onClose, onSave }) => {
  const [nombre, setNombre] = useState("");
  const [codigoAgrupadorSAT, setCodigoAgrupadorSAT] = useState("");
  const [grupo, setGrupo] = useState(1);

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    async function Init() {
      try {
        if (cuentaId > 0) {
          let cuentas = await GetCuentasContables();
          let cuenta = cuentas.filter((x) => x.id === cuentaId);
          if (cuenta.length === 0) {
            setErrorMessage("No existe la cuenta");
            return;
          }
          setNombre(cuenta[0].nombre);
          setCodigoAgrupadorSAT(cuenta[0].codigoAgrupadorSAT);
          setGrupo(cuenta[0].grupo);
        }
      } catch (error) {
        if (error.response) {
          alert(error.response.data);
        } else {
          alert(error.message);
        }
      }
    }
    Init();
  }, []);

  const handleSave = async () => {
    try {
      let cuenta = { id: cuentaId, nombre, codigoAgrupadorSAT, grupo };

      let response = null;
      if (cuentaId === 0) response = await AddCuenta(cuenta);
      else response = await EditCuenta(cuenta);
      if (!response.exitoso) {
        setErrorMessage(response.mensajeError);
        return;
      }

      onSave();
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <div>
      <div className="overlay">
        <div className="modal">
          <div id="updateCuenta" className="modal--content">
            <div className="modal-header">
              <NavBar
                title={cuentaId === 0 ? "Nueva cuenta" : "Editar cuenta"}
                navButtonComponent={
                  <i
                    className="fas fa-times nav-button-back"
                    onClick={onClose}
                  />
                }
              />
            </div>
            <div className="modal-body">
              <div className="card">
                <div className="form form--vertical">
                  <label>Grupo</label>
                  <Select
                    value={grupo}
                    onChange={(e) => setGrupo(e.currentTarget.value)}
                    items={[
                      { id: 1, nombre: "Activo" },
                      { id: 2, nombre: "Pasivo" },
                      { id: 3, nombre: "Capital" },
                      { id: 4, nombre: "Ingresos" },
                      { id: 5, nombre: "Gastos" },
                    ]}
                    itemKey={(cuenta) => cuenta.id}
                    itemDisplay={(cuenta) => cuenta.nombre}
                  />

                  <label>Nombre</label>
                  <input
                    type="text"
                    value={nombre}
                    onChange={(e) => setNombre(e.currentTarget.value)}
                  />

                  <label>Código SAT</label>
                  <input
                    type="text"
                    value={codigoAgrupadorSAT}
                    onChange={(e) =>
                      setCodigoAgrupadorSAT(e.currentTarget.value)
                    }
                  />
                </div>

                <div className="div-right">
                  <button className="btn primary" onClick={() => handleSave()}>
                    Guardar
                  </button>
                </div>
                <div className="form--error font-small">{errorMessage}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateCuenta;
