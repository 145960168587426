import React, { useState, useEffect } from "react";
import NavBar from "../../utils/nav/NavBar";
import Checkbox from "../../utils/checkBox";
import {
  PostProveedor,
  PutProveedor,
} from "../../../services/proveedoresServices";

const EditProveedor = ({ proveedor, onClose, onSave }) => {
  const [edit, setEdit] = useState({ ...proveedor });

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const validate = () => {
    if (!edit.nombre) {
      alert("Escriba el nombre del proveedor");
      return false;
    }

    return true;
  };
  const handleSave = async () => {
    if (!validate()) return;

    try {
      if (edit.id) await PutProveedor(edit);
      else {
        let newProveedor = { ...edit };
        edit.id = await PostProveedor(edit);
      }

      window.scrollTo({ top: 0 });
      onSave(edit);
    } catch (error) {
      alert(error);
    }
  };

  return (
    <div id="edit-proveedor">
      <div className="page-header">
        <NavBar
          title={!edit.id ? "Nuevo proveedor" : "Editar proveedor"}
          navButtonComponent={
            <i
              className="fas fa-arrow-left nav-button-back"
              onClick={() => onClose()}
            />
          }
        />
      </div>
      <div className="page--content">
        <div className="card">
          <h3>Generales</h3>
          <div className="form">
            <label htmlFor="">Nombre:</label>
            <input
              type="text"
              value={edit.nombre}
              onChange={(e) => {
                edit.nombre = e.currentTarget.value;
                setEdit({ ...edit });
              }}
            />
            <label htmlFor="">eMail:</label>
            <input
              type="text"
              value={edit.email}
              onChange={(e) => {
                edit.email = e.currentTarget.value;
                setEdit({ ...edit });
              }}
            />
            <label htmlFor="">Telefono:</label>
            <input
              type="text"
              value={edit.telefonoVentas}
              onChange={(e) => {
                edit.telefonoVentas = e.currentTarget.value;
                setEdit({ ...edit });
              }}
            />
            <label htmlFor="">Domicilio:</label>
            <input
              type="text"
              value={edit.domicilio}
              onChange={(e) => {
                edit.domicilio = e.currentTarget.value;
                setEdit({ ...edit });
              }}
            />
            <label htmlFor="">Colonia:</label>
            <input
              type="text"
              value={edit.colonia}
              onChange={(e) => {
                edit.colonia = e.currentTarget.value;
                setEdit({ ...edit });
              }}
            />

            <label htmlFor="">Municipio:</label>
            <input
              type="text"
              value={edit.municipio}
              onChange={(e) => {
                edit.municipio = e.currentTarget.value;
                setEdit({ ...edit });
              }}
            />

            <label htmlFor="">C.P.:</label>
            <input
              type="text"
              value={edit.codigoPostal}
              onChange={(e) => {
                edit.codigoPostal = e.currentTarget.value;
                setEdit({ ...edit });
              }}
            />
            <label htmlFor="">Entre calles:</label>
            <input
              type="text"
              value={edit.entreCalle1}
              onChange={(e) => {
                edit.entreCalle1 = e.currentTarget.value;
                setEdit({ ...edit });
              }}
            />
            <input
              type="text"
              value={edit.entreCalle2}
              onChange={(e) => {
                edit.entreCalle2 = e.currentTarget.value;
                setEdit({ ...edit });
              }}
            />

            <label htmlFor="">Notas:</label>
            <textarea
              value={edit.notas}
              onChange={(e) => {
                edit.notas = e.currentTarget.value;
                setEdit({ ...edit });
              }}
            />

            <Checkbox
              checked={edit.deshabilitado}
              label="Inactivo"
              onCheckedChanged={() => {
                edit.deshabilitado = !edit.deshabilitado;
                setEdit({ ...edit });
              }}
            />
          </div>
        </div>
        <div className="card">
          <h3>Órdenes de compra</h3>
          <div className="form">
            <Checkbox
              checked={edit.desglosarOCxPedido}
              label="Desglosar orden por pedido"
              onCheckedChanged={() => {
                edit.desglosarOCxPedido = !edit.desglosarOCxPedido;
                setEdit({ ...edit });
              }}
            />
            <Checkbox
              checked={edit.concentrarOCxProducto}
              label="Concentrar orden por producto"
              onCheckedChanged={() => {
                edit.concentrarOCxProducto = !edit.concentrarOCxProducto;
                setEdit({ ...edit });
              }}
            />

            <label htmlFor="">Notas orden:</label>
            <textarea
              value={edit.notasOrdenCompra}
              onChange={(e) => {
                edit.notasOrdenCompra = e.currentTarget.value;
                setEdit({ ...edit });
              }}
            />
          </div>
        </div>
      </div>

      <div className="page-footer">
        <button className="btn secondary" onClick={handleSave}>
          Guardar
        </button>
      </div>
    </div>
  );
};

export default EditProveedor;
