import React, { useState, useEffect } from "react";
import {
  GetProveedores,
  GetPurchaseReceiptLookupProductos,
  PostPurchaseReceipt,
} from "../../../services/purchaseServices";
import QuantityEntry from "../../utils/quantityEntry";
import moment from "moment";
import { round } from "../../../services/mathServices";
import { PutPurchaseReceipt } from "../../../services/purchaseServices";
import AutoComplete from "../../utils/autoComplete";

const PurchaseReceiptItem = ({
  item,
  onUpdateComplete,
  onAddComplete,
  onClose,
  fecha,
}) => {
  const [surtido, setSurtido] = useState("");
  const [importe, setImporte] = useState("");
  const [proveedor, setProveedor] = useState();
  const [producto, setProducto] = useState();
  const [imagen, setImagen] = useState(item.imageUrl);
  const [productos, setProductos] = useState([]);
  const [proveedores, setProveedores] = useState([]);

  useEffect(() => {
    async function cargarCombos() {
      try {
        let productos = await GetPurchaseReceiptLookupProductos();
        let proveedores = await GetProveedores();
        setProductos(productos);
        setProveedores(proveedores);
      } catch (error) {
        alert(error);
      }
    }

    if (item.isNewItem) cargarCombos();

    if (item.surtido) {
      setSurtido(item.surtido);
      setImporte(round(item.importeSurtido, 2));
    }
  }, []);

  const selectProducto = (producto) => {
    if (!producto) {
      setImagen();
      setProducto();
    } else {
      let index = productos.findIndex(
        (p) => p.productoId === producto.productoId
      );
      if (index > -1) {
        let producto = productos[index];
        setImagen(producto.imageUrl);
        setProducto(producto);
        setProveedor(producto.proveedor);
      } else {
        setImagen();
        setProducto();
        setProveedor();
      }
    }
  };

  const guardar = async () => {
    if (item.isNewItem) {
      if (!producto) {
        alert("No ha seleccionado el producto");
        return;
      }
      if (!proveedor) {
        alert("No ha seleccionado al proveedor");
        return;
      }

      if (surtido <= 0) {
        alert("No ha indicado la cantidad recibida");
        return;
      }
    }
    if (surtido > 0 && importe === 0) {
      alert("No ha indicado el costo");
      return;
    }
    if (surtido === 0 && importe > 0) {
      alert("No puede registrar costo sin cantidad");
      return;
    }

    try {
      let response = null;
      if (item.isNewItem) {
        if (!fecha) fecha = moment();
        let request = {
          fecha: moment(fecha).format("YYYY-MM-DD"),
          productoId: producto.productoId,
          proveedorId: proveedor.id,
          surtido: surtido,
          importeSurtido: importe,
          isNewItem: true,
        };
        response = await PostPurchaseReceipt(request);

        onAddComplete(response);
      } else {
        item.surtido = surtido;
        item.importeSurtido = importe;
        response = await PutPurchaseReceipt(item);
        onUpdateComplete(response);
      }
    } catch (error) {
      alert(error);
    }
  };
  return (
    <div className="modal">
      <div className="modal--content">
        <div className="modal--header">
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              onClose();
            }}
          >
            <i className="fas fa-arrow-left" />
          </a>
          <span> Registrar recepcion</span>
        </div>
        <div className="modal--body">
          <div className="purchaseReceipt--item list-item">
            <div className="col1">
              <img className="thumbnail" src={imagen} alt="" />
            </div>
            <div className="col2">
              {item.isNewItem && (
                <div>
                  <AutoComplete
                    placeHolder="Producto"
                    items={productos}
                    value={item.descripcion}
                    itemKey={(item) => item.productoId}
                    itemDisplay={(item) => item.descripcion}
                    itemClassName={(item) => (!item.activo ? " italic " : "")}
                    onItemSelected={(e) => selectProducto(e)}
                  />

                  <AutoComplete
                    placeHolder="Proveedor"
                    items={proveedores}
                    value={proveedor}
                    itemKey={(prov) => prov.id}
                    itemDisplay={(prov) => prov.nombre}
                    onItemSelected={(e) => setProveedor(e)}
                  />
                </div>
              )}
              <div className="list-item--heading">{item.descripcion}</div>
              {!item.isNewItem && (
                <span>
                  Proveedor: <strong>{item.proveedor}</strong>
                </span>
              )}
              <div id="purchase-receipt--item-grid" className="grid">
                {!item.isNewItem && <div className="col1">Solicitado</div>}
                {!item.isNewItem && (
                  <div className="col2 text-align-center">
                    <span className="cantidad">{item.solicitado} </span>
                    <span className="unidad">{item.unidad}</span>
                  </div>
                )}

                <div className="col1">Surtido</div>
                <div className="col2">
                  <QuantityEntry
                    value={surtido}
                    step={1}
                    onValueChanged={(value) =>
                      value == "0" ? setSurtido("") : setSurtido(value)
                    }
                  />
                </div>
                <div className="col1">Importe</div>
                <div className="col2">
                  <QuantityEntry
                    value={importe}
                    step={1}
                    onValueChanged={(value) =>
                      value == "0" ? setImporte("") : setImporte(value)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal--footer">
          <button className="btn primary" onClick={() => guardar()}>
            Guardar
          </button>
        </div>
      </div>
    </div>
  );
};

export default PurchaseReceiptItem;
