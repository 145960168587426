import webAPIServices from "./webAPIServices";
import moment from "moment";

export async function GetRouteOrdersToFullfill(rutaId) {
  const appsvc = new webAPIServices();

  let requestUrl = `/backoffice/fulfillment/ruta/${rutaId}`;

  return appsvc.Get(requestUrl);
}

export async function GetOrderToFulfill(orderId) {
  const appsvc = new webAPIServices();

  let requestUrl = `/backoffice/fulfillment/pedido/${orderId}`;

  return appsvc.Get(requestUrl);
}

//TODO: Catch error on Axios call
export async function PutOrderItemFulfillment(
  orderId,
  orderItemId,
  cantidadSurtida,
  kilosSurtidos
) {
  if (!kilosSurtidos) kilosSurtidos = 0;
  if (!cantidadSurtida) cantidadSurtida = 0;
  const appsvc = new webAPIServices();

  let requestUrl = `/backoffice/fulfillment/partida/${orderItemId}/${cantidadSurtida}?kilosSurtidos=${kilosSurtidos}`;

  return appsvc.Put(requestUrl);
}

export async function SetOrderCompleted(orderId) {
  const appsvc = new webAPIServices();

  let requestUrl = `/backoffice/fulfillment/pedido/${orderId}/completed`;

  return appsvc.Put(requestUrl);
}

export async function SetOrderToBeFulfilled(orderId) {
  const appsvc = new webAPIServices();

  let requestUrl = `/backoffice/fulfillment/pedido/${orderId}/toBeFulfilled`;

  return appsvc.Put(requestUrl);
}

export async function GetProductosPorSurtir(fecha) {
  const appsvc = new webAPIServices();

  if (!fecha) fecha = moment();
  let requestUrl = `/backoffice/fulfillment/productos/${appsvc.FormatFecha(
    fecha
  )}`;

  return appsvc.Get(requestUrl);
}

export async function GetPedidosPorSurtirPorProducto(fecha, producto) {
  const appsvc = new webAPIServices();

  if (!fecha) fecha = moment();
  let requestUrl = `/backoffice/fulfillment/productos/${appsvc.FormatFecha(
    fecha
  )}/${producto.idProducto}`;

  return appsvc.Get(requestUrl);
}
