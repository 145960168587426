import React, { useState } from "react";
import { GetDiferenciaStyle } from "../../utils/utils";
import FulfillOrderPartidaDialog from "./FulfillOrderPartidaDialog";

const FulfillOrderPartida = ({ partida, pedido, onUpdateCantidadSurtida }) => {
  const [showDialog, setShowDialog] = useState(false);

  return (
    <div className="list-item">
      <div className="col1">
        <img className="thumbnail" src={partida.imageUrl} alt="" />
      </div>
      <div className="col2" onClick={() => setShowDialog(true)}>
        <div className="list-item--heading">
          {partida.categoria} / {partida.descripcion}
        </div>
        {partida.notas && <div className="notes">{partida.notas}</div>}
        <div id="order-fulfillment--item-grid" className="grid">
          <div className="col1">Solicitado:</div>
          <div className="col2">
            <div className="pill large selected div-center">
              <div>
                <span className="cantidad">{partida.cantidadSolicitada} </span>
                <span className="unidad">{partida.unidad}</span>
              </div>
            </div>
          </div>
          <div className="col1">Surtido:</div>
          <div className="col2">
            <div
              className={
                "pill large div-center " +
                GetDiferenciaStyle(
                  partida.cantidadSolicitada,
                  partida.cantidadSurtida
                )
              }
            >
              <div>
                <span className="cantidad">{partida.cantidadSurtida} </span>
                <span className="unidad">{partida.unidad}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showDialog && (
        <FulfillOrderPartidaDialog
          disabled={pedido.estatus !== "porSurtir"}
          onUpdateCantidadSurtida={onUpdateCantidadSurtida}
          partida={partida}
          onClose={() => {
            setShowDialog(false);
          }}
        />
      )}
    </div>
  );
};

export default FulfillOrderPartida;
