import React, { useState, useEffect } from "react";
import Authorization from "../../utils/secure/Authorization";
import NavBar from "../../utils/nav/NavBar";
import NavMenu from "../../utils/nav/NavMenu";
import PullToRefresh from "react-simple-pull-to-refresh";
import Search from "../../utils/search";
import Loading from "../../utils/loading";

import DateRangeLink from "../../utils/dateRangeLink";
import {
  RangoFechas,
  GetFechaDesde,
  GetFechaHasta,
  GetPrimerDiaDelMes,
} from "../../../helpers/datesHelper";
import ButtonAdd from "../../utils/buttonAdd";
import { formatNumber } from "../../../services/stringServices";

import "../../../css/gastosPage.css";
import moment from "moment";
import UpdateGasto from "./updateGasto";
import { GetGastos } from "../../../services/gastosServices";

const GastosPage = () => {
  const [permissionChecked, setPermissionChecked] = useState(false);
  const [permissionGranted, setPermissionGranted] = useState(false);

  const [rangoFechas, setRangoFechas] = useState({});

  const [loading, setLoading] = useState(false);

  const [gastos, setGastos] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [searchText] = useState("");

  const [adding, setAdding] = useState(false);
  const [editingId, setEditingId] = useState(null);

  useEffect(() => {
    if (!rangoFechas.Desde) {
      let rango = new RangoFechas(
        GetFechaDesde(GetPrimerDiaDelMes()),
        GetFechaHasta(new Date())
      );
      setRangoFechas(rango);
      cargarDatos(rango);
    }
  }, []);

  const cargarDatos = async (rangoFechas) => {
    setRangoFechas(rangoFechas);
    try {
      setLoading(true);
      let gastos = await GetGastos(rangoFechas);
      setGastos(gastos);
      setFiltered(gastos);
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
    }
  };

  function handleSearch(text) {
    text = text.toLocaleLowerCase();
    let r = gastos.filter(
      (x) =>
        x.descripcion.toLocaleLowerCase().includes(text) ||
        x.concepto.nombre.toLocaleLowerCase().includes(text)
    );
    setFiltered(r);
  }

  if (!permissionChecked)
    return (
      <div id="gastos">
        <Authorization
          permission="Gastos/Lista"
          onPermissionGranted={() => {
            setPermissionChecked(true);
            setPermissionGranted(true);
          }}
        />
      </div>
    );

  if (permissionChecked && !permissionGranted) return <div></div>;

  if (adding)
    return (
      <UpdateGasto
        gastoId={0}
        onClose={() => setAdding(false)}
        onSave={() => {
          setAdding(false);
          cargarDatos(rangoFechas);
        }}
      />
    );

  if (editingId)
    return (
      <UpdateGasto
        gastoId={editingId}
        onClose={() => setEditingId(null)}
        onSave={() => {
          setEditingId(null);
          cargarDatos(rangoFechas);
        }}
      />
    );

  if (permissionChecked && permissionGranted)
    return (
      <div id="gastos">
        <PullToRefresh onRefresh={() => cargarDatos()}>
          <div className="page-header">
            <NavBar
              title="Gastos"
              navButtonComponent={<NavMenu />}
              toolbar={<ButtonAdd onClick={() => setAdding(true)} />}
            />
            <div className="bar single margin-top-1">
              <DateRangeLink
                prefix="Gastos del "
                rangoFechas={rangoFechas}
                onChange={(rango) => cargarDatos(rango)}
              />
            </div>
            <div className="bar single">
              <div className="align-right margin-top-1">
                <span className="margin-right-1">({filtered.length})</span>
                <span>Total: $</span>
                <span className="font-small">
                  {formatNumber(
                    filtered.reduce(
                      (previous, current) => previous + current.monto,
                      0
                    ),
                    2
                  )}
                </span>
              </div>
            </div>
          </div>

          <div className="margin-1">
            <Search
              initialSearchText={searchText}
              onSearch={(searchText) => handleSearch(searchText)}
            />
          </div>

          <div className="page-list">
            {filtered.map((gasto, i) => (
              <div
                key={i}
                className="margin-bottom-1 border-bottom grid"
                onClick={() => setEditingId(gasto.id)}
              >
                <div className="col1">
                  <div>{gasto.concepto.nombre}</div>
                  <div className="font-muted">{gasto.descripcion}</div>
                </div>
                <div id="gasto--monto-fecha" className="col2 div-right">
                  <div className="font-muted">
                    {moment(gasto.fecha).format("DD/MM/YY")}
                  </div>
                  <div className="font-bold">
                    $ {formatNumber(gasto.monto, 2)}
                  </div>
                </div>
              </div>
            ))}
          </div>
          {loading && <Loading />}
        </PullToRefresh>
      </div>
    );

  return <div>Gastos</div>;
};

export default GastosPage;
