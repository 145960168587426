import webAPIServices from "./webAPIServices";
export async function Login(userName, password) {
  const appsvc = new webAPIServices();
  let session = await appsvc.Post(`/controlacceso/login`, {
    nombreUsuario: userName,
    password,
  });
  session.userName = userName;
  return session;
}

export async function Logout(sessionId) {
  const appsvc = new webAPIServices();
  await appsvc.Post(`/controlacceso/session/${sessionId}/logout`);
}

export async function HasPermission(sessionId, permission) {
  const appsvc = new webAPIServices();
  try {
    let response = await appsvc.Get(
      `/controlacceso/session/${sessionId}/Permiso?clave=${permission}`
    );
    if (response) return "";
    else return "No tiene permiso para esta opción";
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) return "Credenciales no válidas";

      if (error.response.status === 440)
        return "Ha caducado la sesión, vuelva a firmarse";

      return "Acceso denegado";
    } else return "Ocurrió un error al validar permiso";
  }
}
