import webAPIServices from "./webAPIServices";

export async function GetCodigosPostales() {
  const appsvc = new webAPIServices();

  let requestUrl = `/codigospostales/lista`;

  return appsvc.Get(requestUrl);
}

export async function DeshabilitarCodigoPostal(codigoPostal) {
  const appsvc = new webAPIServices();

  let requestUrl = `/codigospostales/deshabilitar/${codigoPostal}`;

  return appsvc.Put(requestUrl);
}
export async function HabilitarCodigoPostal(codigoPostal) {
  const appsvc = new webAPIServices();

  let requestUrl = `/codigospostales/habilitar/${codigoPostal}`;

  return appsvc.Put(requestUrl);
}
