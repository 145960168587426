import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import Home from "./components/pages/home";
import OrdersFulfillment from "./components/pages/orders-fulfillment/ordersFulfillment";

import SecureArea from "./components/utils/secure/SecureArea";
import "./css/normalize.css";
import "./css/App.css";
import Settings from "./components/pages/settings/settings";
import Sales from "./components/pages/sales/salesDashboard";
import OrdersFulfillmentByProduct from "./components/pages/orders-fulfillment/OrdersFulfillmentByProduct";
import PurchaseReceipt from "./components/pages/purchase-receipt/purchase-receipt";
import KickOffRoutes from "./components/pages/kickoff-routes/kickoff-routes";
import Products from "./components/pages/products/products";
import Stocks from "./components/pages/products/stocks";
import Proveedores from "./components/pages/proveedores/proveedores";
import UserProfiles from "./components/pages/users/userprofiles";
import Inhabiles from "./components/pages/inhabiles/inhabiles";
import ZipCodes from "./components/pages/zip-codes/zipcodes";
import CxC from "./components/pages/cxc/cxc";
import Info from "./components/utils/info";
import GastosPage from "./components/pages/gastos/gastosPage";
import ConceptosGastoPage from "./components/pages/gastos/ConceptosGastoPage";
import CuentasPage from "./components/pages/cuentas/cuentasPage";
import EstadoResultadosPage from "./components/pages/reportes/estadoResultadosPage";

function App() {
  console.log("ENV", process.env.REACT_APP_ENVIRONMENT);
  return (
    <SecureArea>
      <Router>
        <div id="App">
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/sales">
              <Sales />
            </Route>
            <Route exact path="/orders-fulfillment">
              <OrdersFulfillment />
            </Route>
            <Route exact path="/orders-fulfillment-by-product">
              <OrdersFulfillmentByProduct />
            </Route>
            <Route exact path="/purchase-receipt">
              <PurchaseReceipt />
            </Route>
            <Route exact path="/kickoff-routes">
              <KickOffRoutes />
            </Route>
            <Route exact path="/products">
              <Products />
            </Route>
            <Route exact path="/stocks">
              <Stocks />
            </Route>
            <Route exact path="/settings">
              <Settings />
            </Route>
            <Route exact path="/proveedores">
              <Proveedores />
            </Route>
            <Route exact path="/user-profiles">
              <UserProfiles />
            </Route>
            <Route exact path="/inhabiles">
              <Inhabiles />
            </Route>
            <Route exact path="/zip-codes">
              <ZipCodes />
            </Route>
            <Route exact path="/cxc">
              <CxC />
            </Route>
            <Route exact path="/info">
              <Info />
            </Route>
            <Route exact path="/gastos">
              <GastosPage />
            </Route>
            <Route exact path="/conceptos-gastos">
              <ConceptosGastoPage />
            </Route>
            <Route exact path="/cuentas">
              <CuentasPage />
            </Route>
            <Route exact path="/profitloss">
              <EstadoResultadosPage />
            </Route>
          </Switch>
        </div>
      </Router>
    </SecureArea>
  );
}

export default App;
