import React, { useState } from "react";

import { Login as LoginService } from "../../../services/secureServices";
import { SetBackofficeUserSession } from "../../../services/sessionServices";

import "../../../css/secure.css";
import Loading from "../loading";

const Login = ({ onLoggedIn }) => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [errorText, setErrorText] = useState("");
  const [showWaiting, setShowWaiting] = useState(false);

  async function doLogin() {
    try {
      setErrorText();

      if (!userName) {
        setErrorText("Escriba su nombre de usuario");
        return;
      }

      if (!password) {
        setErrorText("Escriba su contraseña");
        return;
      }

      setShowWaiting(true);

      let response = await LoginService(userName, password);
      SetBackofficeUserSession(response);

      onLoggedIn(response);
    } catch (error) {
      setShowWaiting(false);
      let errorMessage = "Ocurrió un error al iniciar sesión";
      if (error.response && error.response.data) {
        console.log(error.response);
        console.log(error.response.data);
        errorMessage = error.response.data;
      } else {
        console.log("ERROR", error);
      }
      setErrorText(errorMessage);
    }
  }

  return (
    <div id="login">
      <h2>Iniciar sesión</h2>
      <div className="form primary">
        <div className="field-group">
          <label htmlFor="userName">Usuario:</label>
          <input
            type="text"
            name="userName"
            id="userName"
            value={userName}
            placeholder="usuario"
            onChange={(e) => {
              setErrorText("");
              setUserName(e.currentTarget.value);
            }}
          />
        </div>

        <div className="field-group">
          <label htmlFor="password">Contraseña:</label>
          <input
            type="password"
            name="password"
            id="password"
            placeholder="contraseña"
            value={password}
            onChange={(e) => {
              setErrorText("");
              setPassword(e.currentTarget.value);
            }}
          />
        </div>
        <div className="div-right">
          <a href="#" onClick={() => alert("Contacte al administrador")}>
            Olvidé mi contraseña
          </a>
        </div>
        {errorText && (
          <div className="form--error">
            <i className="fas fa-exclamation-triangle form--error-icon"></i>
            {errorText}
          </div>
        )}
        <div id="login-button" className="div-center">
          <button
            className="btn primary outline full-width"
            onClick={() => doLogin()}
          >
            Ingresar
          </button>
        </div>
      </div>
      <img
        className="flip-vertical"
        src={`${process.env.PUBLIC_URL}/img/waves-divider.svg`}
        alt=""
      />
      <div id="login-footer">
        <span>Todo A Mi Puerta&reg; </span> | Backoffice
      </div>
      {showWaiting && <Loading />}
    </div>
  );
};

export default Login;
