import React, { useEffect, useState } from "react";
import NavBar from "../../utils/nav/NavBar";
import ButtonSave from "../../utils/buttonSave";
import Select from "../../utils/select";
import Checkbox from "../../utils/checkBox";
import { GetPerfiles } from "../../../services/usersServices";

const EditUser = ({ usuario, onClose, onSave }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [nombre, setNombre] = useState(usuario.nombre);
  const [eMail, setEmail] = useState(usuario.eMail);
  const [deshabilitado, setDeshabilitado] = useState(usuario.deshabilitado);
  const [perfilUsuarioId, setPerfilUsuarioId] = useState(
    usuario.perfilUsuarioId
  );
  const [perfiles, setPerfiles] = useState([]);

  useEffect(() => {
    const cargarPerfiles = async () => {
      let perfiles = await GetPerfiles();
      setPerfiles(perfiles);
    };
    cargarPerfiles();
    window.scrollTo({ top: 0 });
  }, []);

  const handleSave = () => {
    if (!nombre) {
      alert("Escriba el nombre del usuario");
      return;
    }
    if (!eMail) {
      alert("Escriba el correo del usuario");
      return;
    }
    if (password && password !== confirmPassword) {
      alert("La contraseña y la confirmación no coinciden.");
      return;
    }

    onSave({
      id: usuario.id,
      nombre,
      eMail,
      password,
      deshabilitado,
      perfilUsuarioId,
    });
  };

  return (
    <div id="edit-user">
      <div className="page-header">
        <NavBar
          title={!usuario.id ? "Nuevo usuario" : "Editar usuario"}
          navButtonComponent={
            <i
              className="fas fa-arrow-left nav-button-back"
              onClick={() => onClose()}
            />
          }
          toolbar={<ButtonSave onClick={() => handleSave()} />}
        />
      </div>
      <div className="page--content">
        <form className="form form--vertical" autoComplete="off">
          <input
            autocomplete="false"
            name="hidden"
            type="text"
            style={{ display: "none" }}
          />
          <label htmlFor="">Nombre:</label>
          <input
            type="text"
            autoComplete="off"
            value={nombre}
            onChange={(e) => setNombre(e.currentTarget.value)}
          />
          <label htmlFor="">eMail:</label>
          <input
            type="text"
            autoComplete="off"
            value={eMail}
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
          <label htmlFor="">Contraseña:</label>
          <input
            type="password"
            autoComplete="off"
            value={password}
            onChange={(e) => setPassword(e.currentTarget.value)}
          />
          <label htmlFor="">Confirmar contraseña:</label>
          <input
            type="password"
            autoComplete="off"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.currentTarget.value)}
          />
          {usuario.id && (
            <div>
              <label htmlFor="">Perfil</label>
              <Select
                value={perfilUsuarioId}
                items={perfiles}
                itemKey={(item) => item.id}
                itemDisplay={(item) => item.nombre}
                onChange={(e) => setPerfilUsuarioId(e.currentTarget.value)}
              />
              <Checkbox
                label="Deshabilitado"
                checked={deshabilitado}
                onCheckedChanged={() => setDeshabilitado(!deshabilitado)}
              />
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default EditUser;
