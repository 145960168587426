import { round } from "../../services/mathServices";

export const GetDiferenciaStyle = (solicitado, surtido) => {
  // No ha surtido nada es rojo
  if (surtido <= 0) return "danger";

  // No habia solicitado y tiene surtido es excedente
  if (solicitado === 0) return "color-excedente";

  let pendiente = solicitado - surtido;
  let porcentaje = round(Math.abs(pendiente) / solicitado, 2);

  // Si ha surtido menos de lo solicitado
  if (surtido < solicitado) {
    if (porcentaje > 0.1) return "warning";
    return "success";
  }

  if (porcentaje > 0.1) return "color-excedente";

  return "success";
};
