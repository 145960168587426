import React, { useState, useEffect } from "react";
import NavBar from "../../utils/nav/NavBar";
import QuantityEntry from "../../utils/quantityEntry";
import Checkbox from "../../utils/checkBox";

const CompleteOrderFulfillment = ({ pedido, onClose, onComplete }) => {
  const [numeroCajas, setNumeroCajas] = useState(1);
  const [tieneFaltantes, setTieneFaltantes] = useState(false);
  const [completarConFaltantes, setCompletarConFaltantes] = useState(false);
  useEffect(() => {
    console.log("Complete, pedido", pedido);
    if (!pedido) return;
    let faltantes = pedido.partidas.reduce(
      (sum, current) =>
        current.cantidadPendiente > 0 ? sum + current.cantidadPendiente : sum,
      0
    );
    console.log("faltantes", faltantes);
    setTieneFaltantes(faltantes > 0);
  }, []);

  const handleComplete = () => {
    if (tieneFaltantes && !completarConFaltantes) {
      alert("No ha confirmado que desea completar con faltantes");
      return;
    }

    onComplete(numeroCajas);
  };
  return (
    <div>
      <div className="overlay">
        <div className="modal small">
          <div className="modal-header">
            <NavBar
              title="Marcar como pedido listo"
              navButtonComponent={
                <i className="fas fa-times nav-button-back" onClick={onClose} />
              }
            />
          </div>
          <div className="modal--container">
            <div className="modal-body grid">
              <div className="col1 div-center div-center-vertical">Cajas</div>
              <div className="col2 div-center div-center-vertical">
                <QuantityEntry
                  value={numeroCajas}
                  minValue="1"
                  onValueChanged={(value) => setNumeroCajas(value)}
                />
              </div>
              <div className="span-col2">
                {tieneFaltantes && (
                  <Checkbox
                    checked={completarConFaltantes}
                    label="Marcar como completado con faltantes"
                    onCheckedChanged={() =>
                      setCompletarConFaltantes(!completarConFaltantes)
                    }
                  />
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button className="btn primary" onClick={() => handleComplete()}>
                Listo
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompleteOrderFulfillment;
